import React from 'react';

import * as S from './styles';
import { useTranslation } from 'react-i18next';

export default function CampaignClosed() {
  const { t } = useTranslation();

  return (
    <S.Container>
      <h1>{t('addProduct.buttons.campaign_closed')}</h1>
      <S.Button to={'/'}>{t('addProduct.buttons.volver')}</S.Button>
    </S.Container>
  );
}
