import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

import { setUserInfo_v1 } from "../../services/Apis/setUserInfo_v1";
import setAdditionalInfos from "../../services/Apis/setAdditionalInfos";

import { useLocation, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

import {
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";

import { configJson } from "../../services/firebaseUtils";

import "./additionalInfo.css";
import { useTranslation } from "react-i18next";

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#fff",
    },
    backgroundColor: "#fff",
  },
}))(InputBase);

const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const body = document.querySelector("body");
function FormAddicionalInfo() {
  const { t } = useTranslation();

  const areasWork = [
    "Prótesis total",
    "Protocolo acrílico",
    "Libre de metal",
    "Metal cerámica",
    t("formAdditionalInfo.options.protese_parcial_removivel"),
    "CAD/CAM",
    t("formAdditionalInfo.options.protese_sobre_implante"),
  ];

  const digitalFlowEquipment = [
    "Ninguno",
    "Impresora 3D",
    "Scanner de mesa",
    "Scanner intraoral",
    "Fresadora",
  ];

  const discylcateRestorations = ["01-05", "06-15", "16-20", "21-40", "41+"];

  const [ovenModel, setOvenModel] = useState("");
  const [workAreas, setWorkAreas] = useState([]);
  const [flowEquipment, setFlowEquipment] = useState([]);
  const [discylcateRestoration, setDiscylcateRestoration] = useState("01-05");
  const [frequentBuyer, setFrequentBuyer] = useState("");
  // const [have3dPrinter, setHave3dPrinter] = useState(false);
  const [messageErrorWorkAreas, setMessageWorkAreas] = useState("");
  const [messageErrorApi, setMessageErrorApi] = useState("");
  const [loading, setLoading] = useState(false);

  // DADOS DO FORMULÁRIO ANTERIOR
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    console.log(flowEquipment);
  }, [flowEquipment]);

  useEffect(() => {
    body.style.backgroundColor = "#10233f";
    window.scrollTo(0, 0);
    setMessageWorkAreas("");

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setMessageWorkAreas("");
    setMessageErrorApi("");

    if (workAreas.length === 0) {
      setMessageWorkAreas(t("formAdditionalInfo.alerts.select_area"));
      setLoading(true);

      return;
    }

    const data = {
      ...location.state.partner.partnerList,
      ovenModel,
      workAreas,
      flowEquipment,
      discylcateRestoration,
      frequentBuyer,
    };

    const operatorData = location.state.operator;
    const uId = location.state.uId;

    if (uId !== undefined || uId !== "" || uId !== null) {
      setUserInfo_v1(uId, operatorData)
        .then(() => {
          addDataAdditionalInfo(data, uId);
        })
        .catch((error) => {
          console.log("error api set_user_info: ", error);
          setMessageErrorApi(t("formAdditionalInfo.alerts.went_wrong"));
          setLoading(false);
        });
    }
  }

  async function addDataAdditionalInfo(data, uId) {
    const recipient = data.entityType === "Profissional" ? "prf" : "lab";

    try {
      await setAdditionalInfos(uId, CAMPAIGN_ID, recipient, data);
      // LIMPA O LOCAL_STORAGE
      localStorage.clear();

      setLoading(false);
      history.push("/informe");
    } catch {
      console.log("Error ao adicionar valores no partner...");
      setMessageErrorApi(t("formAdditionalInfo.alerts.went_wrong"));
      setLoading(false);
    }
  }

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

  return (
    <>
      <Header isShowLinks={false} />
      <div className="container-form-additional-info">
        <div className="content-text-form-adicional-info">
          <h1>{t("formAdditionalInfo.common.title")}</h1>

          <p>{t("formAdditionalInfo.common.description_form")}</p>
        </div>

        <form onSubmit={handleSubmit} className="content-form-additional-info">
          <label>{t("formAdditionalInfo.labels.which_furnace")}</label>
          {/* <NativeSelect
            id="item"
            value={ovenModel}
            onChange={(e) => setOvenModel(e.target.value)}
            input={<BootstrapInput />}>
            {option.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </NativeSelect> */}
          <input
            type="text"
            value={ovenModel}
            onChange={(e) => {
              setOvenModel(e.target.value);
            }}
          />

          {messageErrorWorkAreas && (
            <span style={{ color: "red", fontSize: "13px" }}>
              {messageErrorWorkAreas}
            </span>
          )}
          <label id="select-work" style={{ marginTop: "20px" }}>
            {t("formAdditionalInfo.labels.which_areas_work")}
          </label>
          <Select
            htmlFor="select-work"
            id="select-work"
            multiple
            value={workAreas}
            onChange={(e) => setWorkAreas(e.target.value)}
            input={<BootstrapInput />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{ variant: "menu" }}
          >
            {areasWork.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={workAreas.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
          {/* <input
            type="text"
            value={workAreas}
            onChange={(e) => {
              setWorkAreas(e.target.value);
            }}
          /> */}

          <label id="select-work" style={{ marginTop: "20px" }}>
            {t("formAdditionalInfo.labels.digital_flow_equipment")}
          </label>
          <Select
            htmlFor="select-equip"
            id="select-equip"
            multiple
            value={flowEquipment}
            onChange={(e) => setFlowEquipment(e.target.value)}
            input={<BootstrapInput />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{ variant: "menu" }}
          >
            {digitalFlowEquipment.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={flowEquipment.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
          {/* <NativeSelect
            id="item"
            value={flowEquipment}
            onChange={(e) => setFlowEquipment(e.target.value)}
            input={<BootstrapInput />}
          >
            {digitalFlowEquipment.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </NativeSelect> */}

          <label id="select-work" style={{ marginTop: "20px" }}>
            {t("formAdditionalInfo.labels.discylcate_restorations")}
          </label>
          <NativeSelect
            id="item"
            style={{ marginBottom: "20px" }}
            value={discylcateRestoration}
            onChange={(e) => setDiscylcateRestoration(e.target.value)}
            input={<BootstrapInput />}
          >
            {discylcateRestorations.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </NativeSelect>
          <label id="select-work">
            {t("formAdditionalInfo.labels.buy_ivoclar")}
          </label>
          <Select
            id="item"
            style={{ marginBottom: "20px" }}
            value={frequentBuyer}
            onChange={(e) => setFrequentBuyer(e.target.value)}
            input={<BootstrapInput />}
          >
            <MenuItem value={true}>Sí</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>

          {/* <label style={{ marginTop: "20px" }}>
            {t("formAdditionalInfo.labels.has_printer_3d")}
          </label>
          <div className="content-radio-form-additional-info">
            <div className="custom-radio-form-additional-info">
              <input
                checked={have3dPrinter}
                id="radio-yes"
                onChange={() => {}}
                onClick={() => setHave3dPrinter(true)}
                name="machine"
                type="radio"
                value="yes"
              />
              <label htmlFor="radio-yes">
                {t("formAdditionalInfo.options.yes")}
              </label>
            </div>

            <div className="custom-radio-form-additional-info">
              <input
                checked={!have3dPrinter}
                id="radio-no"
                onChange={() => {}}
                onClick={() => setHave3dPrinter(false)}
                name="machine"
                value="no"
                type="radio"
              />
              <label htmlFor="radio-no">
                {t("formAdditionalInfo.options.no")}
              </label>
            </div>
          </div> */}

          {messageErrorApi && (
            <span
              style={{ color: "red", fontSize: "13px", marginBottom: "20px" }}
            >
              {messageErrorApi}
            </span>
          )}

          <button
            disabled={
              loading ||
              workAreas.length === 0 ||
              ovenModel === "" ||
              flowEquipment.length === 0 ||
              discylcateRestoration === "" ||
              frequentBuyer.length === 0
            }
            className="button-customers"
            type="submit"
            style={{
              width: "100%",
              backgroundColor: `${
                workAreas.length === 0 ||
                flowEquipment.length === 0 ||
                ovenModel === "" ||
                frequentBuyer.length === 0
                  ? "#b6e1f3"
                  : "#42baef"
              }`,
              cursor: `${
                workAreas.length === 0 ||
                flowEquipment.length === 0 ||
                ovenModel === ""
                  ? "no-drop"
                  : "pointer"
              }`,
            }}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "#fff" }} />
            ) : (
              t("formAdditionalInfo.buttons.check_information")
            )}
          </button>
        </form>

        <div style={{ width: "100%" }}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default FormAddicionalInfo;
