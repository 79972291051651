const premios = [
  { name: 'IPS e.max Press HO 5 pcs', code: '596753' },
  { name: 'IPS e.max Press HO 1 5 pcs', code: '626302' },
  { name: 'IPS e.max Press HO 2 5 pcs', code: '626303' },
  { name: 'IPS e.max Press MO 0 5 pcs', code: '596756' },
  { name: 'IPS e.max Press MO 1 5 pcs', code: '596757' },
  { name: 'IPS e.max Press MO 2 5 pcs', code: '596758' },
  { name: 'IPS e.max Press MO 3 5 pcs', code: '596759' },
  { name: 'IPS e.max Press MO 4 5 pcs', code: '596760' },
  { name: 'IPS e.max Press MT A1 5 pcs', code: '668756' },
  { name: 'IPS e.max Press MT A2 5 pcs', code: '668757' },
  { name: 'IPS e.max Press MT A3 5 pcs', code: '668758' },
  { name: 'IPS e.max Press MT B1 5 pcs', code: '668759' },
  { name: 'IPS e.max Press MT BL2 5 pcs', code: '668891' },
  { name: 'IPS e.max Press MT BL3 5 pcs', code: '668770' },
  { name: 'IPS e.max Press MT BL4 5 pcs', code: '668771' },
  { name: 'IPS e.max Press LT A1 5 pcs', code: '605273' },
  { name: 'IPS e.max Press LT A2 5 pcs', code: '605274' },
  { name: 'IPS e.max Press LT A3 5 pcs', code: '605275' },
  { name: 'IPS e.max Press LT B1 5 pcs', code: '605277' },
  { name: 'IPS e.max Press LT B2 5 pcs', code: '605278' },
  { name: 'IPS e.max Press LT B3 5 pcs', code: '605279' },
  { name: 'IPS e.max Press LT C1 5 pcs', code: '626308' },
  { name: 'IPS e.max Press LT C2 5 pcs', code: '605280' },
  { name: 'IPS e.max Press LT D3 5 pcs', code: '605281' },
  { name: 'IPS e.max Press LT BL1 5 pcs', code: '605293' },
  { name: 'IPS e.max Press LT BL2 5 pcs', code: '605294' },
  { name: 'IPS e.max Press LT BL3 5 pcs', code: '605295' },
  { name: 'IPS e.max Press LT BL4 5 pcs', code: '605296' },
  { name: 'IPS e.max Press HT A1 5 pcs', code: '626320' },
  { name: 'IPS e.max Press HT A2 5 pcs', code: '626321' },
  { name: 'IPS e.max Press HT A3 5 pcs', code: '626322' },
  { name: 'IPS e.max Press HT A3.5 5 pcs', code: '626356' },
  { name: 'IPS e.max Press HT B1 5 pcs', code: '626325' },
  { name: 'IPS e.max Press HT B2 5 pcs', code: '626326' },
  { name: 'IPS e.max Press HT B3 5 pcs', code: '626327' },
  { name: 'IPS e.max Press HT C1 5 pcs', code: '626329' },
  { name: 'IPS e.max Press HT C2 5 pcs', code: '626330' },
  { name: 'IPS e.max Press HT D3 5 pcs', code: '626334' },
  { name: 'IPS e.max Press HT BL1 5 pcs', code: '626352' },
  { name: 'IPS e.max Press HT BL2 5 pcs', code: '626353' },
  { name: 'IPS e.max Press HT BL3 5 pcs', code: '626354' },
  { name: 'IPS e.max Press HT BL4 5 pcs', code: '626355' },
];

const category = [
  { label: 'IPS e.max Press HO', value: 'HO' },
  { label: 'IPS e.max Press MO', value: 'MO' },
  { label: 'IPS e.max Press MT', value: 'MT' },
  { label: 'IPS e.max Press LT', value: 'LT' },
  { label: 'IPS e.max Press HT', value: 'HT' },
];

const product = {
  HO: [
    { name: 'IPS e.max Press HO 5 pcs', code: '596753' },
    { name: 'IPS e.max Press HO 1 5 pcs', code: '626302' },
    { name: 'IPS e.max Press HO 2 5 pcs', code: '626303' },
  ],
  MO: [
    { name: 'IPS e.max Press MO 0 5 pcs', code: '596756' },
    { name: 'IPS e.max Press MO 1 5 pcs', code: '596757' },
    { name: 'IPS e.max Press MO 2 5 pcs', code: '596758' },
    { name: 'IPS e.max Press MO 3 5 pcs', code: '596759' },
    { name: 'IPS e.max Press MO 4 5 pcs', code: '596760' },
  ],
  MT: [
    { name: 'IPS e.max Press MT A1 5 pcs', code: '668756' },
    { name: 'IPS e.max Press MT A2 5 pcs', code: '668757' },
    { name: 'IPS e.max Press MT A3 5 pcs', code: '668758' },
    { name: 'IPS e.max Press MT B1 5 pcs', code: '668759' },
    { name: 'IPS e.max Press MT BL2 5 pcs', code: '668891' },
    { name: 'IPS e.max Press MT BL3 5 pcs', code: '668770' },
    { name: 'IPS e.max Press MT BL4 5 pcs', code: '668771' },
  ],
  LT: [
    { name: 'IPS e.max Press LT A1 5 pcs', code: '605273' },
    { name: 'IPS e.max Press LT A2 5 pcs', code: '605274' },
    { name: 'IPS e.max Press LT A3 5 pcs', code: '605275' },
    { name: 'IPS e.max Press LT B1 5 pcs', code: '605277' },
    { name: 'IPS e.max Press LT B2 5 pcs', code: '605278' },
    { name: 'IPS e.max Press LT B3 5 pcs', code: '605279' },
    { name: 'IPS e.max Press LT C1 5 pcs', code: '626308' },
    { name: 'IPS e.max Press LT C2 5 pcs', code: '605280' },
    { name: 'IPS e.max Press LT D3 5 pcs', code: '605281' },
    { name: 'IPS e.max Press LT BL1 5 pcs', code: '605293' },
    { name: 'IPS e.max Press LT BL2 5 pcs', code: '605294' },
    { name: 'IPS e.max Press LT BL3 5 pcs', code: '605295' },
    { name: 'IPS e.max Press LT BL4 5 pcs', code: '605296' },
  ],
  HT: [
    { name: 'IPS e.max Press HT A1 5 pcs', code: '626320' },
    { name: 'IPS e.max Press HT A2 5 pcs', code: '626321' },
    { name: 'IPS e.max Press HT A3 5 pcs', code: '626322' },
    { name: 'IPS e.max Press HT A3.5 5 pcs', code: '626356' },
    { name: 'IPS e.max Press HT B1 5 pcs', code: '626325' },
    { name: 'IPS e.max Press HT B2 5 pcs', code: '626326' },
    { name: 'IPS e.max Press HT B3 5 pcs', code: '626327' },
    { name: 'IPS e.max Press HT C1 5 pcs', code: '626329' },
    { name: 'IPS e.max Press HT C2 5 pcs', code: '626330' },
    { name: 'IPS e.max Press HT D3 5 pcs', code: '626334' },
    { name: 'IPS e.max Press HT BL1 5 pcs', code: '626352' },
    { name: 'IPS e.max Press HT BL2 5 pcs', code: '626353' },
    { name: 'IPS e.max Press HT BL3 5 pcs', code: '626354' },
    { name: 'IPS e.max Press HT BL4 5 pcs', code: '626355' },
  ],
};

export { premios, category, product };
