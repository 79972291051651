import React, { useState, useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import ProvideSignUpForm from '../contexts/signUpForm';
import ProviderUser from '../contexts/user';

import Home from '../pages/Home';
import SignUp from '../pages/SignUp';
import BusinessInfo from '../pages/BusinessInfo';
import CustomersInfo from '../pages/CustomersInfo';
import Information from '../pages/Information';
import AddProduct from '../pages/AddProduct';
import RequestPrize from '../pages/RequestPrize';
import GivePrize from '../pages/GivePrize';
import Tutorial from '../pages/Tutorial';
import FormAddicionalInfo from '../components/FormAdditionalInfo';
import ProviderDentalList from '../contexts/dentalList';
import { WidgetWhatsapp } from '../components/WidgetWhatsapp';
import CampaignClosed from '../pages/CampaignClosed';

function CustomRoute({ isPrivate, ...rest }) {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    function loadingStorage() {
      const storageUser = localStorage.getItem('IvoclarUser');

      if (storageUser) {
        setAuthenticated(true);
      }
    }

    loadingStorage();
  }, []);

  if (isPrivate && !authenticated) {
    return <Redirect to='/' />;
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <ProviderUser>
      <ProvideSignUpForm>
        <ProviderDentalList>
          <WidgetWhatsapp />
          <Switch>
            <CustomRoute exact path='/' component={Home} />
            <CustomRoute exact path='/cadastrar' component={SignUp} />
            <CustomRoute
              exact
              path='/cadastrar-laboratorio'
              component={BusinessInfo}
            />
            <CustomRoute
              exact
              path='/cadastrar-dentista'
              component={CustomersInfo}
            />
            <CustomRoute
              exact
              path='/cadastro-adicional'
              component={FormAddicionalInfo}
            />
            <CustomRoute exact path='/informe' component={Information} />
            <CustomRoute
              exact
              path='/adicionar-produto'
              component={CampaignClosed}
            />
            <CustomRoute
              exact
              path='/solicitar-premio'
              component={CampaignClosed}
            />
            <CustomRoute
              exact
              path='/entregar-premio'
              component={CampaignClosed}
            />
            <CustomRoute exact path='/tutorial' component={Tutorial} />
            <CustomRoute path='*' component={Home} />
          </Switch>
        </ProviderDentalList>
      </ProvideSignUpForm>
    </ProviderUser>
  );
}
