import React, { useState, useEffect, useContext } from "react";
import { SignUpFormContext } from "../../contexts/signUpForm";
import { configJson } from "../../services/firebaseUtils";

import { useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { UserContext } from "../../contexts/user";

import "./signUp.css";
import { useTranslation } from "react-i18next";
import { setUserInfo_v1 } from "../../services/Apis/setUserInfo_v1";
import { CircularProgress } from "@material-ui/core";

const ORG_ID_IVOCLAR = configJson.ORG_ID_IVOCLAR;

const body = document.querySelector("body");

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 10,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: "#fff",
    },
    backgroundColor: "#fff",
  },
}))(InputBase);

function SignUp() {
  const { t } = useTranslation();
  // PEGA OS DADOS DO CONTEXT
  const { setNameUser, setLastNameUser, setEmailUser, setUserRole } =
    useContext(SignUpFormContext);

  const optionRole = [
    t("signUp.labels.options.dentist"),
    t("signUp.labels.options.tec_dentist"),
    t("signUp.labels.options.assistant"),
  ];

  const localStorageUserData = JSON.parse(localStorage.getItem("IvoclarUser"));

  const [selectedRole, setSelectedRole] = useState("Prestador");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailChecked, setEmailChecked] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [checkInfo, setCheckInfo] = useState(false);

  const history = useHistory();

  const { loading, setLoading, setMessageErroCode, setMessage, cellPhone } =
    useContext(UserContext);

  const formData = {
    name: `${name.trim()} ${lastName.trim()}`,
    lastName: lastName.trim(),
    aliasName: name.trim(),
    email: email,
  };

  function storageRole() {
    const data = {
      selectedRole,
    };

    localStorage.setItem("UserRole", JSON.stringify(data));
  }

  function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    storageRole();
    // CASO O EMAIL DIGATADO NÃO SEJA IGUAIS RETORNA ERRO
    if (email !== emailChecked) {
      setIsEmailValid(true);
      return;
    }

    // SALVA OS DADOS NO CONTEXT
    setNameUser(name);
    setLastNameUser(lastName);
    setEmailUser(email);
    setUserRole(selectedRole);
    addUserData();

    // if (ValidateCpf(cpfUser.replace(/\D/g, ""))) {
    //   toggleModalLogin();
    // } else {
    //   setMessageErroCpf(t("messageError.common.invalid_document"));
    // }
  }

  useEffect(() => {
    body.style.backgroundColor = "#10233f";
    body.style.overflow = "";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsEmailValid(false);
  }, [email, emailChecked]);

  //VALIDA O FORMULÁRIO
  useEffect(() => {
    if (name !== "" && lastName !== "" && email !== "" && emailChecked !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, lastName, email, emailChecked]);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

  async function addUserData() {
    const user = localStorageUserData ? localStorageUserData : null;

    if (!user) {
      setMessage("Erro ao cadastrar dados.");
      return;
    }

    const plantnerName = "ivoclar";
    const newKeyWordsSetLimit = [plantnerName];
    const newKeyWordsSetCustom = [plantnerName];
    const newKeyWordsSetConsumption = [plantnerName];
    const newOrgs = [ORG_ID_IVOCLAR];
    const orgsUser = localStorageUserData.orgs;
    const uIdUser = localStorageUserData.uId;

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetLimit) &&
      user?.keyWordsSetLimit.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetLimit.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetCustom) &&
      user?.keyWordsSetCustom.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetCustom.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.KeyWordsSetConsumption) &&
      user?.KeyWordsSetConsumption.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetConsumption.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM ORGS
    Array.isArray(orgsUser) &&
      orgsUser.forEach((org) => {
        org !== ORG_ID_IVOCLAR && newOrgs.push(org);
      });

    const currentDate = new Date().getTime();
    //INFORMAÇÕES PARA CHAMAR A API SET_USER_INFO
    const userData = {
      authenticationMethodList: [
        {
          cellPhone: cellPhone,
          dateVerified: currentDate,
          enabled: true,
          lastlogin: currentDate,
          type: "SMS - Triibo",
          verified: true,
        },
      ],
      contactList: [
        {
          type: "cellPhone",
          value: cellPhone,
        },
        {
          type: "email",
          value: formData?.email,
        },
      ],
      keyWordsSetLimit: [...newKeyWordsSetLimit],
      keyWordsSetCustom: [...newKeyWordsSetCustom],
      keyWordsSetConsumption: [...newKeyWordsSetConsumption],
      orgs: [...newOrgs],
      name: formData?.name,
      aliasName: formData?.aliasName,
      updateDate: currentDate,
    };

    // API QUE ADICIONA DADOS NO USUÁRIO
    setUserInfo_v1(uIdUser, userData)
      .then((result) => {
        setLoading(false);
        history.push({ pathname: "/cadastrar-laboratorio", state: formData });
      })
      .catch((error) => {
        console.log("CONTEXT_USER_ERROR: ", error);
        setLoading(false);
        setMessage("Algo saiu errado, tente novamente mais tarde.");
        setMessageErroCode("Algo saiu errado, tente novamente mais tarde.");
      });
  }

  return (
    <>
      <Header isShowLinks={false} />
      <div className="container-signUp">
        <h1>{t("signUp.common.title")}</h1>

        <p>
          {t("signUp.common.description_part1")}{" "}
          <strong style={{ textDecoration: "underline" }}>
            {t("signUp.common.description_part2")}
          </strong>{" "}
          {t("signUp.common.description_part3")}
        </p>

        <form onSubmit={handleSubmit} className="main-content">
          <>
            <div className="area-input">
              <label>{t("signUp.labels.what_profession")}</label>
              <NativeSelect
                id="role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                input={<BootstrapInput />}
              >
                {optionRole.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </NativeSelect>
              <label style={{ marginTop: "9px" }}>
                {t("signUp.labels.what_name")}
              </label>
              <input
                type="text"
                name="name"
                placeholder={t("signUp.placeholders.name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>{t("signUp.labels.what_lastName")}</label>
              <input
                type="text"
                name="lastName"
                placeholder={t("signUp.placeholders.lastName")}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="area-input">
              {<span style={{ color: "red", fontSize: "13px" }}></span>}
              {isEmailValid && (
                <label style={{ color: "red", fontSize: "13px" }}>
                  {t("signUp.alerts.same_emails")}
                </label>
              )}
              <label>{t("signUp.labels.email")}</label>
              <input
                style={{
                  border: `${isEmailValid ? "1px solid red" : "0px"}`,
                }}
                type="email"
                name="email"
                placeholder="email@exemplo.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>{t("signUp.labels.email_confirmation")}</label>
              <input
                style={{
                  border: `${isEmailValid ? "1px solid red" : "0px"}`,
                }}
                type="email"
                name="email_checked"
                placeholder="email@exemplo.com"
                value={emailChecked}
                onChange={(e) => setEmailChecked(e.target.value)}
              />

              <label className="container-checkbox">
                <span className="text-checkbox">
                  {t("signUp.common.accept_info")}
                </span>
                <input
                  type="checkbox"
                  value={checkInfo}
                  onClick={() => {
                    setCheckInfo(!checkInfo);
                  }}
                  className="checked"
                />
                <span className="checkmark"></span>
              </label>

              <button
                disabled={!checkInfo || !isFormValid || loading}
                style={{
                  backgroundColor: `${
                    isFormValid && checkInfo ? "#42baef" : "#b6e1f3"
                  }`,
                  cursor: `${isFormValid && checkInfo ? "pointer" : "no-drop"}`,
                  marginTop: "5rem",
                }}
                type="submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  t("signUp.button.next")
                )}
              </button>
            </div>
          </>
        </form>

        {/* MODAL LOGIN */}
        {/* {showModalLogin && (
          <ModalSignUp
            close={toggleModalLogin}
            nextPage={nextPage}
            data={formData}
            modalType={modalType}
            hasIvoclar={hasIvoclar}
          />
        )} */}

        <Footer />
      </div>
    </>
  );
}

export default SignUp;
