import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { configJson } from "../../services/firebaseUtils";

import ModalGivePrize from "../../components/ModalGivePrize";
import { getUserInfo_v1 } from "../../services/Apis/getUserInfo_v1";
import { getWallet_v1 } from "../../services/Apis/getWallet_v1";
import { getStorePlaces_v2 } from "../../services/Apis/getStorePlaces_v2";
import { getPrizesRequestWithoutEmail } from "../../services/Apis/voucherLog";
import getCampaign from "../../services/Apis/getCampaign";

// INPUT PESQUISA

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./giveprize.css";
import { useTranslation } from "react-i18next";
import getCheckDentalUser from "../../services/Apis/getCheckDentalUser";
import getDental from "../../services/Apis/getDental";
import Form from "./Form";
import SelectDental from "./SelectDental";
import Loading from "./Loading";

const body = document.querySelector("body");

// GERA ARRAY DE BOOLEAN
const generateBooleanValue = (lenght) =>
  Array.from(Array(lenght).keys()).map(() => false);

const CAMPAIGN_ID = configJson.CAMPAIGN_ID;
const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;

function GivePrize() {
  const [cellPhone, setCellPhone] = useState("");
  const [listProducts, setListProducts] = useState([]);
  const [prizeSelected, setPrizeSelected] = useState([]);
  const [copyPrizeSelected, setCopyPrizeSelected] = useState([]);
  const [amountCoupons, setAmountCoupons] = useState(0);
  const [amountCouponsUnsolicited, setAmountCouponsUnsolicited] = useState(0);
  const [totalPrize, setTotalPrize] = useState(0);

  const [dentalData, setDentalData] = useState(null);
  const [winnerData, setWinnerData] = useState(null);

  const [partnerDataWinner, setPartnerDataWinner] = useState(null);
  const [additionalInfoWinner, setAdditionalInfoWinner] = useState(null);

  const [vouchers, setVouchers] = useState([]);
  const [vouchersUnrequised, setVouchersUnrequised] = useState([]);

  const [allKeysVouchers, setAllKeyVouchers] = useState([]);

  const [apiVoucherKey, setApiVoucherKey] = useState([]);
  const [copyApiVoucherKey, setCopyApiVoucherKey] = useState([]);

  const [apiVoucherKeyUnrequised, setApiVoucherKeyUnrequised] = useState([]);
  const [copyApiVoucherUnrequised, setCopyApiVoucherUnrequised] = useState([]);

  const [templatId, setTemplateId] = useState([]);
  const [copyTemplatId, setCopyTemplatId] = useState([]);
  const [storesIds, setStoresIds] = useState([]);
  const [copyStoresIds, setCopyStoresIds] = useState([]);

  const [checked, setChecked] = useState([]);
  const [checkedUnsolicited, setCheckedUnsolicited] = useState([]);

  const [hasPrize, setHasPrize] = useState(false);
  const [messagePrize, setMessagePrize] = useState("");
  const [showModalInformation, setShowModalInforme] = useState(false);

  const [messageErrorCellPhone, setMessageErrorCellPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [triiboIdDental, setTriiboIdDental] = useState("");
  const [uIdDental, setUidDental] = useState("");
  const [uIdWinner, setUidWinner] = useState("");
  const [teethWinnerBought, setTeethWinnerBought] = useState([]);
  const [dentalsIsAdmin, setDentalsIsAdmin] = useState([]);
  const [dentalSelected, setDentalSelected] = useState("");
  const [winnerHasCupons, setWinnerHasCupons] = useState(false);

  const [prizeText, setPrizeText] = useState("");

  // ESTADO DOS PRÊMIOS QUE AINDA NÃO FORAM SOLICITADOS
  const [storesIdsUnsolicited, setStoresIdsUnsolicited] = useState([]);
  const [copyStoresIdsUnsolicited, setCopyStoresIdsUnsolicited] = useState([]);
  const [templatIdUnsolicited, setTemplateIdUnsolicited] = useState([]);
  const [copyTemplatIdUnsolicited, setCopyTemplatIdUnsolicited] = useState([]);
  const [prizeSelectedUnsolicited, setPrizeSelectedUnsolicited] = useState([]);
  const [copyPrizeSelectedUnsolicited, setCopyPrizeSelectedUnsolicited] =
    useState([]);

  const [allStoreCosumerIdsConsume, setAllStoreIdsConsume] = useState([]);
  const [allTemplaIdsConsume, setAllTemplaIdsConsume] = useState([]);

  const [currentCountry, setCurrentCountry] = useState("");
  const [currentDDD, setCurrentDDD] = useState("");
  const [inputField, setInputField] = useState("");
  const [inputMask, setInputMask] = useState("");
  const [fullMaskSelected, setFullMaskSelected] = useState("+55(00)00000-0000");

  const [loadingPage, setLoadingPage] = useState(true);
  const [currentComponent, setCurrentComponent] = useState(0);

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(","); //transforma a string em um array a partir da vírgula
    setInputField("");
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMaskSelected(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputField(e.target.value);
  };

  useEffect(() => {
    setCellPhone(
      "+" + currentDDD.replace(/\D/g, "") + inputField.replace(/\D/g, "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  const history = useHistory();
  const { t } = useTranslation();

  function handleForm() {
    setMessagePrize("");

    const claerArray = prizeSelected.filter(
      (prizeName) => prizeName !== "" && prizeName !== undefined
    );
    const clearArrayUnsolicited = prizeSelectedUnsolicited.filter(
      (item) => item !== "" && item !== undefined
    );

    const allProducts = claerArray.concat(clearArrayUnsolicited);
    const allkeyVouchers = apiVoucherKey.concat(apiVoucherKeyUnrequised);

    const allStoreIds = storesIds.concat(storesIdsUnsolicited);
    const allTemplateIds = templatId.concat(templatIdUnsolicited);

    const isValidPrizeSelected = allProducts.length !== 0;

    const keyVouchers = allkeyVouchers.filter(
      (item) => item !== "" && item !== undefined
    );

    setAllKeyVouchers(keyVouchers);

    if (isValidPrizeSelected) {
      let productNames = "";

      allProducts.forEach((item, index) => {
        productNames += `${item}${
          allProducts.length - 1 === index ? "." : ", "
        }`;
      });

      setPrizeText(productNames);
      setAllStoreIdsConsume(allStoreIds);
      setAllTemplaIdsConsume(allTemplateIds);
      setShowModalInforme(true);
    } else {
      setShowModalInforme(false);
      !isValidPrizeSelected &&
        setMessagePrize(t("givePrize.alerts.select_product"));
    }
  }

  function handleSelectedPrize(product, indexInput, voucherKey) {
    apiVoucherKey[indexInput] = voucherKey;
    setApiVoucherKey([...apiVoucherKey]);
    setCopyApiVoucherKey([...apiVoucherKey]);

    storesIds[indexInput] = product.id;
    setStoresIds([...storesIds]);
    setCopyStoresIds([...storesIds]);

    templatId[indexInput] = product.promotion.templateId;
    setTemplateId([...templatId]);
    setCopyTemplatId([...templatId]);

    prizeSelected[indexInput] = product.promotion.title.trim();
    setPrizeSelected([...prizeSelected]);
    setCopyPrizeSelected([...prizeSelected]);
  }

  function handleSelectedPrizeUnsolicited(product, indexInput, voucherKey) {
    apiVoucherKeyUnrequised[indexInput] = voucherKey;
    setApiVoucherKeyUnrequised([...apiVoucherKeyUnrequised]);
    setCopyApiVoucherUnrequised([...apiVoucherKeyUnrequised]);

    storesIdsUnsolicited[indexInput] = product.id;
    setStoresIdsUnsolicited([...storesIdsUnsolicited]);
    setCopyStoresIdsUnsolicited([...storesIdsUnsolicited]);

    templatIdUnsolicited[indexInput] = product.promotion.templateId;
    setTemplateIdUnsolicited([...templatIdUnsolicited]);
    setCopyTemplatIdUnsolicited([...templatIdUnsolicited]);

    prizeSelectedUnsolicited[indexInput] = product.promotion.title.trim();
    setPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
    setCopyPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
  }

  function handleChecked(value, index) {
    setMessagePrize("");
    checked[index] = value;
    setChecked([...checked]);

    if (!value) {
      apiVoucherKey[index] = "";
      prizeSelected[index] = "";
      templatId[index] = "";
      storesIds[index] = "";
    }

    if (value && copyPrizeSelected.length !== 0) {
      apiVoucherKey[index] = "";
      apiVoucherKey[index] = copyApiVoucherKey[index];
      setApiVoucherKey([...apiVoucherKey]);

      storesIds[index] = "";
      storesIds[index] = copyStoresIds[index];
      setStoresIds([...storesIds]);

      templatId[index] = "";
      templatId[index] = copyTemplatId[index];
      setTemplateId([...templatId]);

      prizeSelected[index] = "";
      prizeSelected[index] = copyPrizeSelected[index];
      setPrizeSelected([...prizeSelected]);
    }
  }

  function handleCheckedUnsolicited(value, index) {
    setMessagePrize("");
    checkedUnsolicited[index] = value;
    setCheckedUnsolicited([...checkedUnsolicited]);

    if (!value) {
      apiVoucherKeyUnrequised[index] = "";
      prizeSelectedUnsolicited[index] = "";
      templatIdUnsolicited[index] = "";
      storesIdsUnsolicited[index] = "";
    }

    if (value && copyPrizeSelectedUnsolicited.length !== 0) {
      apiVoucherKeyUnrequised[index] = "";
      apiVoucherKeyUnrequised[index] = copyApiVoucherUnrequised[index];
      setApiVoucherKeyUnrequised([...apiVoucherKeyUnrequised]);

      storesIdsUnsolicited[index] = "";
      storesIdsUnsolicited[index] = copyStoresIdsUnsolicited[index];
      setStoresIdsUnsolicited([...storesIdsUnsolicited]);

      templatIdUnsolicited[index] = "";
      templatIdUnsolicited[index] = copyTemplatIdUnsolicited[index];
      setTemplateIdUnsolicited([...templatIdUnsolicited]);

      prizeSelectedUnsolicited[index] = "";
      prizeSelectedUnsolicited[index] = copyPrizeSelectedUnsolicited[index];
      setPrizeSelectedUnsolicited([...prizeSelectedUnsolicited]);
    }
  }

  function filteredStoreList(listPrize) {
    let listProductsFilter = listPrize.filter((store) => {
      if (
        store?.type === "promotion"
        // store?.promotion?.hasOwnProperty("keyWordsSet")
      ) {
        if (store?.promotion.hasOwnProperty("hasGroup")) {
          return null;
        }
        return store;
      } else {
        return null;
      }
    });

    listProductsFilter.sort((a, b) => {
      let x = a.promotion.title.toLowerCase(),
        y = b.promotion.title.toLowerCase();

      // eslint-disable-next-line
      return x == y ? 0 : x > y ? 1 : -1;
    });

    return listProductsFilter;
  }

  function checkWallet(walletReceived) {
    // PARÂMETROS PARA VERIFICAR O DIREITO AO PRÊMIO
    const establishmentId = configJson.ESTABLISHMENT_ID;
    const type = "store";
    const status = "enviado";

    const activeCoupons = [];
    const inactiveCoupons = [];

    // VERIFICA SE TEM CUPONS PARA RESGATE
    if (walletReceived !== null) {
      if (walletReceived.coupons !== null) {
        Object.keys(walletReceived.coupons).map(function (item, index) {
          let element = Object.keys(walletReceived.coupons)[index];
          walletReceived.coupons[item].key = element;
          if (
            walletReceived.coupons[item].establishmentId === establishmentId &&
            walletReceived.coupons[item].status === status &&
            walletReceived.coupons[item].type === type
          ) {
            return activeCoupons.push(walletReceived.coupons[item]);
          } else {
            return inactiveCoupons.push(walletReceived.coupons[item]);
          }
        });
      }
    }

    return {
      activeCoupons,
      inactiveCoupons,
    };
  }

  // BUSCA OS PRÊMIOS JÁ SOLICITADOS
  async function requestedPrizeSearch(uId) {
    try {
      const response = await getPrizesRequestWithoutEmail(uId, "requestPrize");
      let prizesData = [];

      //MONTA O ARRAY PARA EXIBIR TODOS OS PRÉMIOS JÁ SOLICITADOS PELA DENTAL SELECIONADA.
      if (response.length > 0) {
        response.forEach((item) => {
          item.storesNews.forEach((product) => {
            let infoVoucher = { ...product, email: item.businessInfo.email };
            prizesData.push(infoVoucher);
          });
        });
      }

      return prizesData;
    } catch {
      console.log("ERRO_LOG_QUEST_PRIZE");
      setLoading(false);
      setMessageErrorCellPhone(t("givePrize.alerts.went_wrong"));
    }
  }

  async function handleSubmit(event) {
    setMessageErrorCellPhone("");

    // APAGA O TOKEN COM O UID DO ULTIMO USUÁRIO PESQUISADO PARA EVITAR ERROS
    localStorage.removeItem("ivoclarToken");
    event.preventDefault();
    const isCellPhoneValid =
      cellPhone.replace(/\D/g, "").length ===
      fullMaskSelected.replace(/\D/g, "").length;
    const triiboId = `${cellPhone.replace(/\D/g, "")}@sms,triibo,com,br`;
    const clearCellPhone = `${cellPhone.replace(/\D/g, "")}`;

    // TELEFONE GANHADOR
    const userInfo = {
      contactList: [
        {
          type: "cellPhone",
          value: clearCellPhone,
        },
      ],
    };

    // TELEFONE DENTAL
    const userInfoDental = {
      contactList: [
        {
          type: "cellPhone",
          value: triiboIdDental.slice(0, 14),
        },
      ],
    };

    if (isCellPhoneValid) {
      setLoading(true);
      try {
        const resultUser = await getUserInfo_v1(userInfo);
        const resultInfoDental = await getUserInfo_v1(userInfoDental);

        // VERIFICA SE O USUÁRIO EXISTE
        if (resultUser === false) {
          setLoading(false);
          setMessageErrorCellPhone(t("givePrize.alerts.user_not_found"));
          return;
        }

        // VERIFICA SE O USUÁRIO PARTICIPA DA CAMPANHA ATUAL.
        const resultCampaign = await getCampaign(
          resultUser.uId,
          CAMPAIGN_ID,
          "1"
        );

        if (!resultCampaign.data.status) {
          setLoading(false);
          setMessageErrorCellPhone(
            t("givePrize.alerts.user_not_register_in_promotion")
          );
          return;
        }

        // PEGA O PARTNER DO GANHADOR
        resultUser?.partnerList.forEach((partner) => {
          if (partner.partnerId === PartnerIdIvoclar) {
            setPartnerDataWinner(partner);
          }
        });

        // PEGA O ADDITIONAL_INFO DO PARNER DO GANHADOR
        resultUser?.partnerList.forEach((partner) => {
          if (partner.partnerId === PartnerIdIvoclar) {
            partner.additionalInfo.forEach((additional) => {
              if (additional.campaignId === CAMPAIGN_ID) {
                setAdditionalInfoWinner(additional);
              }
            });
          }
        });

        setDentalData(resultInfoDental);
        setWinnerData(resultUser);
        setUidWinner(resultUser?.uId);

        // VERIFICA A CARTEIRA DO GANHADOR
        const walletReceived = await getWallet_v1(resultUser?.uId, triiboId);
        const { activeCoupons } = checkWallet(walletReceived);

        if (activeCoupons.length !== 0) {
          let emailDental = "";
          // PEGA O EMAIL DA DENTAL
          if (resultInfoDental?.partnerList) {
            const partnerList = resultInfoDental.partnerList;

            partnerList.forEach((partner) => {
              if (partner.partnerId === PartnerIdIvoclar) {
                partner.contactList.forEach((contact) => {
                  if (contact.type === "email") emailDental = contact.value;
                });
              }
            });
          }

          // VERIFICA QUAIS PRÊMIOS O USUÁRIO SOLICITOU PARA DENTAL
          const prizesRequest = await requestedPrizeSearch(resultUser?.uId);

          let prizesRequestActive = [];
          let prizesUnrequised = [];

          // VERIFICA SE O GANHADOR SOLICITOU ALGUM PRÊMIO
          if (prizesRequest.length > 0) {
            let listVouchers = activeCoupons;
            let dataIvoclar = prizesRequest;

            let listIvoclarCods = [];

            for (let i in dataIvoclar) {
              const log = dataIvoclar[i];

              listIvoclarCods.push(log.cod);
            }

            // REMOVE OS CUMPONS QUE JÁ FORAM SOLICITADOS
            for (let i in listVouchers) {
              const voucher = listVouchers[i];
              if (!listIvoclarCods.includes(voucher.key))
                prizesUnrequised.push(voucher);
            }

            prizesRequest.forEach((item) => {
              activeCoupons.forEach((counpon) => {
                // VERIFICA SE FOI SOLICITADO O PREMIO PARA A DENTAL QUE ESTA ENTREGANDO O PRÊMIO.
                if (dentalsIsAdmin.length > 0 && item.cod === counpon.key) {
                  prizesRequestActive.push(counpon);
                }
              });
            });

            if (
              prizesRequestActive.length === 0 &&
              prizesUnrequised.length === 0
            ) {
              setMessageErrorCellPhone(
                t("givePrize.alerts.user_is_not_entitled_prize")
              );
              setLoading(false);
              return;
            }
          } else {
            prizesUnrequised = activeCoupons;
          }

          const listPrize = await getStorePlaces_v2(
            uIdDental,
            configJson.ESTABLISHMENT_ID
          );
          const listProductsFilter = filteredStoreList(listPrize);

          setAmountCoupons(prizesRequestActive.length);
          setAmountCouponsUnsolicited(prizesUnrequised.length);
          setTotalPrize(prizesRequestActive.length + prizesUnrequised.length);

          setVouchers(prizesRequestActive);
          setVouchersUnrequised(prizesUnrequised);

          setListProducts(listProductsFilter);
          setHasPrize(true);
        }

        setLoading(false);
        if (walletReceived.coupons === null || activeCoupons.length === 0) {
          setMessageErrorCellPhone(
            t("givePrize.alerts.user_is_not_entitled_prize")
          );
          return;
        }
      } catch (error) {
        setLoading(false);
        setMessageErrorCellPhone(t("givePrize.alerts.went_wrong"));
      }
    } else {
      setMessageErrorCellPhone(t("givePrize.alerts.invalid_cellphone"));
    }
  }

  useEffect(() => {
    setChecked([...generateBooleanValue(amountCoupons)]);
    setCheckedUnsolicited([...generateBooleanValue(amountCouponsUnsolicited)]);
  }, [amountCoupons, amountCouponsUnsolicited]);

  //BUSCA DADOS DA URL
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userIdDental = urlParams.get("userId");
    const triiboIdDentalUrl = urlParams.get("triiboId");

    const isValidParamUidDental =
      userIdDental === "" ||
      userIdDental === undefined ||
      userIdDental === null;

    const isValidParamTriiboIdDental =
      triiboIdDentalUrl === "" ||
      triiboIdDentalUrl === undefined ||
      triiboIdDentalUrl === null;

    if (isValidParamUidDental && isValidParamTriiboIdDental) {
      history.push("/");
      return;
    }

    const clearTriiboIdUrl = `+${triiboIdDentalUrl
      .trim()
      .replace(/[,]/gi, ".")}`;

    setTriiboIdDental(clearTriiboIdUrl);
    setUidDental(userIdDental);

    const checkDentalUser = async () => {
      setLoadingPage(true);

      if (
        userIdDental !== "" &&
        userIdDental !== undefined &&
        userIdDental !== null
      ) {
        try {
          const checkDental = await getCheckDentalUser(userIdDental);
          setDentalsIsAdmin(checkDental.dentals);

          if (checkDental.dentals.length === 1) {
            setDentalSelected(checkDental.dentals[0].establishmentId);
            setCurrentComponent(2);
            setTimeout(() => {
              setLoadingPage(false);
            }, 1000);

            return;
          }

          if (checkDental.dentals.length > 1) {
            setCurrentComponent(1);
            setTimeout(() => {
              setLoadingPage(false);
            }, 1000);

            return;
          }

          if (checkDental.dentals.length === 0) {
            setCurrentComponent(0);
            setTimeout(() => {
              setLoadingPage(false);
            }, 1000);

            return;
          }
        } catch (error) {
          setDentalsIsAdmin(false);

          setTimeout(() => {
            setLoadingPage(false);
          }, 1000);
        }
      } else {
        return;
      }
    };

    checkDentalUser();
  }, [history]);

  useEffect(() => {
    setLoadingPage(true);

    const getDentalApi = async () => {
      try {
        if (uIdWinner !== "" && uIdWinner !== undefined && uIdWinner !== null) {
          const dentalsResponse = await getDental(uIdWinner);
          const jsonObject = dentalsResponse.map(JSON.stringify);
          const uniqueSet = new Set(jsonObject);
          const uniqueArray = Array.from(uniqueSet).map(JSON.parse);

          if (dentalsResponse) {
            setTimeout(() => {
              setLoadingPage(false);
            }, 1000);
          }

          setTeethWinnerBought(uniqueArray);

          return uniqueArray;
        }
      } catch (error) {
        console.log(error);
      }
    };

    getDentalApi();
  }, [uIdWinner]);

  useEffect(() => {
    const winnerHasCuponsFromDental = teethWinnerBought.find(
      (dental) => dental.id === dentalSelected
    );

    winnerHasCuponsFromDental && setWinnerHasCupons(true);
  }, [teethWinnerBought, dentalSelected]);

  useEffect(() => {
    setPrizeSelected([]);
    setCopyPrizeSelected([]);

    setTemplateId([]);
    setCopyTemplatId([]);

    setStoresIds([]);
    setCopyStoresIds([]);

    setShowModalInforme(false);
    setMessagePrize("");
    setHasPrize(false);
    setLoading(false);
    setMessageErrorCellPhone("");
    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  const arrElements = [
    <h1
      style={{
        textAlign: "center",
        color: "#fff",
      }}
    >
      {t("givePrize.common.no_access")}
    </h1>,
    <SelectDental
      setDentalSelected={setDentalSelected}
      dentalSelected={dentalSelected}
      dentalsIsAdmin={dentalsIsAdmin}
      setCurrentComponent={setCurrentComponent}
    />,
    <Form
      handleSubmit={handleSubmit}
      setMessageErrorCellPhone={setMessageErrorCellPhone}
      hasPrize={hasPrize}
      messageErrorCellPhone={messageErrorCellPhone}
      currentCountry={currentCountry}
      handleCountryChange={handleCountryChange}
      currentDDD={currentDDD}
      inputMask={inputMask}
      inputField={inputField}
      handleInputChange={handleInputChange}
      loading={loading}
      totalPrize={totalPrize}
      partnerDataWinner={partnerDataWinner}
      additionalInfoWinner={additionalInfoWinner}
      cellPhone={cellPhone}
      messagePrize={messagePrize}
      vouchers={vouchers}
      listProducts={listProducts}
      checked={checked}
      handleSelectedPrize={handleSelectedPrize}
      handleChecked={handleChecked}
      vouchersUnrequised={vouchersUnrequised}
      winnerHasCupons={winnerHasCupons}
      checkedUnsolicited={checkedUnsolicited}
      handleSelectedPrizeUnsolicited={handleSelectedPrizeUnsolicited}
      handleCheckedUnsolicited={handleCheckedUnsolicited}
      handleForm={handleForm}
    />,
  ];

  return (
    <>
      <Header isShowLinks={false} />
      <div
        style={{ height: `${!hasPrize ? "100vh" : "100%"}` }}
        className="container-give-prize"
      >
        <ModalGivePrize
          close={setShowModalInforme}
          open={showModalInformation}
          cellPhone={cellPhone}
          product={prizeText}
          itemId={allKeysVouchers}
          templatId={allTemplaIdsConsume}
          storesIds={allStoreCosumerIdsConsume}
          triiboIdDental={triiboIdDental}
          uIdDental={uIdDental}
          uIdWinner={uIdWinner}
          stablishmentId={dentalSelected}
          dataInfo={{ dental: dentalData, winner: winnerData }}
        />
        {loadingPage ? <Loading /> : arrElements[currentComponent]}

        {!hasPrize && <Footer />}
      </div>
      {hasPrize && <Footer />}
    </>
  );
}

export default GivePrize;
