import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import NativeSelect from "@material-ui/core/NativeSelect";
import Checkbox from "@material-ui/core/Checkbox";
import BootstrapInput from "./styleSelected";

import LoadingPageExternal from "../../components/LoadingExternalPage";
import ModalRequestPrize from "../../components/ModalRequestPrize";
import { getWallet_v1 } from "../../services/Apis/getWallet_v1";
import { getPrizesRequestWithoutEmail } from "../../services/Apis/voucherLog";
import { configJson } from "../../services/firebaseUtils";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MessageRequestPrize from "./MessageRequestPrize";

import { getUserInfo_v1 } from "../../services/Apis/getUserInfo_v1";

import { category, product } from "../../constants/productsList";

// INPUT PESQUISA
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { TextField } from "@material-ui/core";

import "./requestprize.css";
import { useTranslation } from "react-i18next";
import getDental from "../../services/Apis/getDental";

const CustomTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent;
      color: #000;
    }
    &:hover fieldset {
      border-color: transparent;
    }
    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
`;

const body = document.querySelector("body");

const generateBooleanValue = (lenght) =>
  Array.from(Array(lenght).keys()).map(() => false);

function RequestPrize() {
  const { t } = useTranslation();

  const [allDentalList, setAllDentalList] = useState([]);
  const [dentalSelected, setDentalSelected] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [copyPrizeSelected, setCopyPrizeSelected] = useState([]);
  const [amountCoupons, setAmountCoupons] = useState(0);
  const [amountPrizes, setAmountPrizes] = useState(0);
  const [arrayPrize, setArrayPrize] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [prizeSelected, setPrizeSelected] = useState([]);
  const [prizesAlreadyRequested, setPrizesAlreadyRequested] = useState([]);

  const [checked, setChecked] = useState([]);
  const [hasPrize, setHasPrize] = useState(false);
  const [uId, setUid] = useState("");
  const [triiboId, setTriiboId] = useState("");

  const [errorApi, setErrorApi] = useState(false);
  const [showModalInformation, setShowModalInforme] = useState(false);
  const [messageDental, setMessageDental] = useState("");
  const [messagePrize, setMessagePrize] = useState("");
  const [loading, setLoading] = useState(true);

  const [dentalText, setDentalText] = useState("");
  const [prizeText, setPrizeText] = useState("");
  const [prizeAndCode, setPrizeAndCode] = useState("");

  const [inputValueAutocomplete, setInputValueAutocomplete] = useState([]);

  const [originUrl, setOriginUrl] = useState("");

  const [activeCoupons, setActiveCoupons] = useState([]);

  const history = useHistory();

  function handleForm() {
    setMessageDental("");
    setMessagePrize("");

    const isValidDentalSelected = dentalSelected.length !== 0;
    const isValidPrizeSelected = prizeSelected.length !== 0;
    const claerArray = prizeSelected.filter(
      (prize) => prize !== "" && prize !== undefined
    );
    const emptyString = claerArray.length !== 0;

    if (isValidDentalSelected && isValidPrizeSelected && emptyString) {
      const dentalName = `${dentalSelected.nome}`;
      let productNames = "";
      let productNameAndCode = "";

      claerArray.forEach((item, index) => {
        productNames += `${item.name}${
          claerArray.length - 1 === index ? "." : ", "
        }`;
        productNameAndCode += `Producto: ${item.name} - Código: ${item.code}${
          claerArray.length - 1 === index ? "." : ", "
        }`;
      });

      // PREPARA O ARRAY DA API VOUCHER_LOG
      const prizeList = claerArray.map((item) => {
        return {
          name: item.name,
          cod: item.key,
        };
      });

      setDentalText(dentalName);
      setPrizeText(productNames);
      setPrizeAndCode(productNameAndCode);
      setAmountPrizes(claerArray.length);
      setArrayPrize(prizeList);
      setShowModalInforme(true);
    } else {
      setShowModalInforme(false);
      !isValidDentalSelected &&
        setMessageDental(t("requestPrize.alerts.select_dental"));
      !isValidPrizeSelected &&
        setMessagePrize(t("requestPrize.alerts.select_product"));
      !emptyString && setMessagePrize(t("requestPrize.alerts.select_product"));
    }
  }

  function handleChecked(value, index) {
    setMessagePrize("");
    checked[index] = value;
    setChecked([...checked]);
    if (!value) {
      prizeSelected[index] = "";
    }

    if (value && copyPrizeSelected.length !== 0) {
      prizeSelected[index] = "";
      prizeSelected[index] = copyPrizeSelected[index];
      setPrizeSelected([...prizeSelected]);
    }
  }

  function handleSelectedDental(index) {
    setMessageDental("");
    if (index === "default") {
      setDentalSelected([]);
      return;
    }

    setDentalSelected(allDentalList[index]);
  }

  function handleSelectedPrize(product, indexInput, keyVoucher) {
    const objectProduxt = {
      ...product,
      key: keyVoucher,
    };

    prizeSelected[indexInput] = objectProduxt;

    setPrizeSelected([...prizeSelected]);
    setCopyPrizeSelected([...prizeSelected]);
  }

  function handleSelectedCategory(categorySelected, indexInput) {
    inputValueAutocomplete[indexInput] = "";
    setInputValueAutocomplete([...inputValueAutocomplete]);

    selectedCategory[indexInput] = product[categorySelected.value];
    prizeSelected[indexInput] = "";
    copyPrizeSelected[indexInput] = "";

    setPrizeSelected([...prizeSelected]);
    setCopyPrizeSelected([...copyPrizeSelected]);
    setSelectedCategory([...selectedCategory]);
  }

  function getPrizesRequestNotDelivered(activeCoupons, prizesRequest) {
    let prizesRequestInactives = [];

    let listVouchers = prizesRequest;
    let dataIvoclar = activeCoupons;

    let listIvoclarCods = [];

    for (let i in dataIvoclar) {
      const log = dataIvoclar[i];

      listIvoclarCods.push(log.key);
    }

    for (let i in listVouchers) {
      const voucher = listVouchers[i];
      if (listIvoclarCods.includes(voucher.cod))
        prizesRequestInactives.push(voucher);
    }

    return { prizesRequestInactives };
  }

  async function checkWalletUser(userId) {
    // PARÂMETROS PARA VERIFICAR O DIREITO AO PRÊMIO
    const establishmentId = configJson.ESTABLISHMENT_ID;
    const type = "store";
    const status = "enviado";

    getWallet_v1(userId)
      .then(async (walletReceived) => {
        // VERIFICA SE TEM CUPONS PARA RESGATE
        if (walletReceived !== null) {
          let activeCoupons = [];
          let inactiveCoupons = [];

          if (walletReceived.coupons !== null) {
            Object.keys(walletReceived.coupons).map(function (item, index) {
              let element = Object.keys(walletReceived.coupons)[index];
              walletReceived.coupons[item].key = element;
              if (
                walletReceived.coupons[item].establishmentId ===
                  establishmentId &&
                walletReceived.coupons[item].status === status &&
                walletReceived.coupons[item].type === type
              ) {
                return activeCoupons.push(walletReceived.coupons[item]);
              } else {
                return inactiveCoupons.push(walletReceived.coupons[item]);
              }
            });
          }

          const clearCellPhone = walletReceived.triiboId.split("@")[0];

          // INFORMAÇÕES PARA CHAMAR A API GET_USER_INFO
          const userInfo = {
            contactList: [
              {
                type: "cellPhone",
                value: clearCellPhone,
              },
            ],
          };

          getUserInfo_v1(userInfo)
            .then((resultUser) => {
              if (resultUser === null) {
                return setErrorApi(true);
              }
              setUserInfo(resultUser);
            })
            .catch((error) => {
              setLoading(false);
              setErrorApi(true);
            });

          // VERIFICA QUAIS PRÊMIOS O USUÁRIO SOLICITOU PARA DENTAL
          const prizesRequest = await requestedPrizeSearch(userId);
          const { prizesRequestInactives } = getPrizesRequestNotDelivered(
            activeCoupons,
            prizesRequest
          );
          setLoading(false);

          setPrizesAlreadyRequested(prizesRequestInactives);

          let prizesRequestActive = [];

          let listVouchers = activeCoupons;
          let dataIvoclar = prizesRequest;

          let listIvoclarCods = [];

          for (let i in dataIvoclar) {
            const log = dataIvoclar[i];

            listIvoclarCods.push(log.cod);
          }

          // REMOVE OS CUMPONS QUE JÁ FORAM SOLICITADOS
          for (let i in listVouchers) {
            const voucher = listVouchers[i];
            if (!listIvoclarCods.includes(voucher.key))
              prizesRequestActive.push(voucher);
          }

          if (prizesRequestActive.length > 0) {
            setHasPrize(true);
            setActiveCoupons(prizesRequestActive);
          }
          setAmountCoupons(prizesRequestActive.length);
          setChecked([...generateBooleanValue(prizesRequestActive.length)]);
          setTriiboId(walletReceived.triiboId);
        } else {
          setLoading(false);
          setHasPrize(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorApi(true);
      });
  }

  useEffect(() => {
    // PEGA A ORIGIN DA URL
    const referrer = document.referrer;
    setOriginUrl(referrer);

    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  useEffect(() => {
    setAmountPrizes(0);
    setArrayPrize([]);
    setMessageDental("");
    setMessagePrize("");
    setLoading(true);
    setHasPrize(true);
    setErrorApi(false);
    setAmountCoupons(0);
    setShowModalInforme(false);
    setDentalSelected([]);
    //buscando variáveis da URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get("userId");

    if (userId === "" || userId === undefined || userId === null) {
      history.push("/");
      return;
    }

    const getDentalApi = async () => {
      const dentals = await getDental(userId);

      const jsonObject = dentals.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      setAllDentalList(uniqueArray);
    };

    getDentalApi();

    checkWalletUser(userId);
    setUid(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  // BUSCA OS PRÊMIOS JÁ SOLICITADOS
  async function requestedPrizeSearch(uId) {
    try {
      const response = await getPrizesRequestWithoutEmail(uId, "requestPrize");
      let prizesData = [];

      //MONTA O ARRAY PARA EXIBIR TODOS OS PRÉMIOS JÁ SOLICITADOS PELA DENTAL SELECIONADA.
      if (response.length > 0) {
        response.forEach((item) => {
          item.storesNews.forEach((product) => {
            let infoVoucher = {
              ...product,
              email: item.businessInfo.email,
              nameDental: item.businessInfo.name,
            };
            prizesData.push(infoVoucher);
          });
        });
      }

      return prizesData;
    } catch {
      setLoading(false);
      setErrorApi(true);
    }
  }

  if (loading) {
    return <LoadingPageExternal />;
  } else {
    return (
      <div className="container-request-prize">
        {!errorApi && <Header isShowLinks={false} />}
        <ModalRequestPrize
          close={setShowModalInforme}
          open={showModalInformation}
          dental={dentalText}
          product={prizeText}
          productAndCode={prizeAndCode}
          emailDental={dentalSelected.email}
          uId={uId}
          triiboId={triiboId}
          establishmentId={dentalSelected.id}
          user={userInfo}
          amountPrizes={amountPrizes}
          arrayPrize={arrayPrize}
        />

        {hasPrize && !errorApi ? (
          <>
            <h1 style={{ marginTop: "10%" }}>
              {t("requestPrize.common.title")}
            </h1>
            <p>
              {t("requestPrize.common.description")} {amountCoupons} IPS e.max
            </p>
            <form className="form-request-prize">
              <div className="area-form-requestprize">
                {messageDental !== "" && (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {messageDental}
                  </span>
                )}
                <label hidden={activeCoupons.length === 0}>
                  {t("requestPrize.labels.refer_dental")}
                </label>
                <div style={{ width: "100%" }}>
                  <NativeSelect
                    id="dental"
                    hidden={activeCoupons.length === 0}
                    onChange={(event) =>
                      handleSelectedDental(event.target.value)
                    }
                    input={<BootstrapInput />}
                    className="native-select-dental-request-prize"
                  >
                    <option value={"default"}>
                      {t("requestPrize.labels.select_dental")}
                    </option>
                    {allDentalList.map((item, index) => {
                      return (
                        <option key={index} value={index}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </div>

                <h4>{t("requestPrize.common.avaible_for_redemption")}</h4>
                {messagePrize !== "" && (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    {messagePrize}
                  </span>
                )}
                {/* LISTA DE CATEGORIAS */}
                {activeCoupons.length === 0 ? (
                  <div
                    style={{ marginBottom: "20px" }}
                    className="area-text-info-gize-prize"
                  >
                    <p>{t("requestPrize.common.unavailable_for_redemption")}</p>
                  </div>
                ) : (
                  activeCoupons.map((coupon, inputIndex) => {
                    return (
                      <div key={inputIndex} style={{ width: "100%" }}>
                        <div
                          key={inputIndex}
                          className="area-selected-prize-request"
                        >
                          <div className="content-input-cod-request-prize">
                            <label className="label-preview--request-prize">{`Cupom: ${
                              inputIndex + 1
                            }`}</label>
                            <input
                              value={coupon.key}
                              type="text"
                              disabled
                              className="input-code-number-request-prize"
                            />
                          </div>

                          {/* LISTA DE CATEGORIAS */}
                          <div className="select-prize--request-prize">
                            <label className="label-preview--request-prize">
                              {t("requestPrize.labels.category")}{" "}
                            </label>
                            <Autocomplete
                              id="category_id"
                              options={category}
                              disabled={!checked[inputIndex]}
                              noOptionsText={t(
                                "requestPrize.alerts.invalid_product"
                              )}
                              disableClearable={true}
                              getOptionLabel={(option) => option.label}
                              getOptionSelected={(option, value) =>
                                option.label === value.label
                              }
                              onChange={(_, newValue) => {
                                if (newValue !== null) {
                                  handleSelectedCategory(newValue, inputIndex);
                                }
                              }}
                              renderOption={(option) => option.label}
                              className="select-prize--request-prize"
                              renderInput={(params) => (
                                <CustomTextField
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                  }}
                                  onChange={() => {}}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                  variant="outlined"
                                  placeholder={t(
                                    "requestPrize.placeholders.select_category"
                                  )}
                                  size="small"
                                  autoComplete="off"
                                  autoCorrect="off"
                                  id="field1"
                                />
                              )}
                            />
                          </div>

                          {/* LISTA DE PRODUTOS */}
                          <div className="select-prize--request-prize">
                            <label className="label-preview--request-prize">
                              {t("requestPrize.labels.product")}{" "}
                            </label>
                            <Autocomplete
                              inputValue={
                                inputValueAutocomplete[inputIndex] ?? ""
                              }
                              onBlur={() => {
                                inputValueAutocomplete[inputIndex] =
                                  prizeSelected[inputIndex]?.name ?? "";
                                setInputValueAutocomplete([
                                  ...inputValueAutocomplete,
                                ]);
                              }}
                              id={`field_${inputIndex}`}
                              options={selectedCategory[inputIndex] ?? []}
                              disabled={
                                !checked[inputIndex] ||
                                !selectedCategory[inputIndex]
                              }
                              noOptionsText={t(
                                "requestPrize.alerts.invalid_product"
                              )}
                              disableClearable={true}
                              getOptionSelected={(option, value) =>
                                option.name === value.name
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(option) => option.name}
                              onChange={(_, newValue) => {
                                if (newValue !== null) {
                                  handleSelectedPrize(
                                    newValue,
                                    inputIndex,
                                    coupon.key
                                  );
                                  inputValueAutocomplete[inputIndex] =
                                    newValue.name;
                                  setInputValueAutocomplete([
                                    ...inputValueAutocomplete,
                                  ]);
                                }
                              }}
                              className="select-prize--request-prize"
                              renderInput={(params) => (
                                <CustomTextField
                                  onChange={(e) => {
                                    inputValueAutocomplete[inputIndex] =
                                      e.target.value;
                                    setInputValueAutocomplete([
                                      ...inputValueAutocomplete,
                                    ]);
                                  }}
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                  }}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                  variant="outlined"
                                  placeholder={t(
                                    "requestPrize.placeholders.find_product"
                                  )}
                                  size="small"
                                  autoComplete="off"
                                  autoCorrect="off"
                                />
                              )}
                            />
                          </div>
                          <div className="content-checkbox-area-request-prize">
                            <Checkbox
                              checked={checked[inputIndex] ?? false}
                              onChange={() => {
                                handleChecked(!checked[inputIndex], inputIndex);
                              }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                              style={{ color: "#42baef" }}
                            />
                            <span className="checkbox-text-prize">
                              {t("requestPrize.labels.request_now")}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                {activeCoupons.length !== 0 && (
                  <>
                    <p style={{ marginTop: "40px" }}>
                      {t("requestPrize.common.cannot_change_dental")}
                    </p>
                    <p style={{ marginTop: "6px" }}>
                      {t("requestPrize.common.redemption_exists")}
                    </p>

                    <button
                      className="button-request-prize"
                      disabled={activeCoupons.length === 0}
                      onClick={handleForm}
                      type="button"
                    >
                      {t("requestPrize.buttons.request_award")}
                    </button>
                  </>
                )}

                {/* PRÊMIOS JÁ SOLICITADOS */}
                <>
                  {prizesAlreadyRequested.length > 0 && (
                    <h4>
                      {t("requestPrize.labels.request_for_delivery_fail")}
                    </h4>
                  )}

                  {prizesAlreadyRequested.length > 0 &&
                    prizesAlreadyRequested.map((item, index) => (
                      <div
                        key={index}
                        className="container-preview--request-prize"
                      >
                        <div className="item-preview--request-prize">
                          <label className="label-preview--request-prize">{`Cupom: ${
                            index + 1
                          }`}</label>
                          <input value={item?.cod} type="text" disabled />
                        </div>

                        <div className="item-preview--request-prize">
                          <label className="label-preview--request-prize">
                            {t("requestPrize.labels.product")}{" "}
                          </label>
                          <input value={item?.name} type="text" disabled />
                        </div>

                        <div className="item-preview--request-prize">
                          <label className="label-preview--request-prize">
                            Dental{" "}
                          </label>
                          <input
                            value={item?.nameDental}
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                    ))}
                </>
              </div>
            </form>
          </>
        ) : (
          <MessageRequestPrize errorApi={errorApi} originUrl={originUrl} />
        )}

        {!errorApi && <Footer />}
      </div>
    );
  }
}

export default RequestPrize;
