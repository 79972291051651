import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FiX } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";

import sendEmail from "../../services/Apis/sendEmail";
import { voucherLogRequest } from "../../services/Apis/voucherLog";

import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CRYPTO_KEY = configJson.CRYPTO_KEY;
const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalRequestPrize({
  close,
  open,
  uId,
  user,
  triiboId,
  dental,
  product,
  productAndCode,
  emailDental,
  amountPrizes,
  arrayPrize,
  establishmentId,
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [originUrl, setOriginUrl] = useState("");
  const [messageErrorVoucher, setMessageErrorVoucher] = useState("");
  const [cellphoneValidation, setCellphoneValidation] = useState("");

  const clearCellPhone = triiboId.slice(0, 14);

  useEffect(() => {
    // PEGA A ORIGIN DA URL
    const referrer = document.referrer;
    setOriginUrl(referrer);

    setMessageError("");
    setMessageSuccess("");
    setLoading(false);
  }, []);

  function resetPage() {
    setMessageError("");
    setMessageSuccess("");
    setMessageErrorVoucher("");
    setLoading(false);
  }

  function createTemplateEmail() {
    let telefone = "";
    let email = "";
    let tipo = "";
    let nomeEmpresa = "";
    let endereco = "";

    user.contactList?.forEach((item) => {
      if (item.type === "cellPhone") {
        telefone = item.value;
      }

      if (item.type === "email") {
        email = item.value;
      }
    });

    user.partnerList?.forEach((item) => {
      if (item.partnerName === "IvoClar") {
        item.additionalInfo.forEach((partner) => {
          if (partner.campaignId === CAMPAIGN_ID) {
            if (partner.entityType === "Profissional") {
              tipo = partner.entityType;
              nomeEmpresa = user.name;
              endereco = partner.addressList[0];
            } else {
              tipo = partner.entityType;
              nomeEmpresa = partner.corporateName;
              endereco = partner.addressList[0];
            }
          }
        });
      }
    });

    const textEmail = `Olá,
    ${""}
    Nombre: ${user.name}
    Teléfono: ${telefone}
    Correo electrónico: ${email}
      ${""}
      Está solicitando el canje ${
        amountPrizes <= 1
          ? "del siguiente artículo:"
          : "los siguientes artículos:"
      }
      ${""}
      ${productAndCode}
      ${""}
      En su organización.
      ${""}
      En nombre de:
      Nombre: ${nomeEmpresa}
      Tipo: ${tipo}
      Dirección: ${endereco?.city} - ${endereco?.state}, ${
      endereco?.street
    } - ${endereco?.number}
      ${""}
      Para realizar la entrega utiliza la App de Triibo Identificándote con un celular autorizado.
    `;

    return textEmail;
  }

  async function sendEmailDental() {
    setLoading(true);

    const templateEmail = createTemplateEmail();

    try {
      const result = await voucherLogRequest(
        uId,
        dental,
        emailDental,
        arrayPrize,
        establishmentId
      );

      if (!result.voucherLog?.campaignId && !result.voucherLog?.status) {
        const voucherConsumed = result.voucherLog?.notAllowed;
        const length = voucherConsumed.length;
        const templateMessage = `${
          length > 1
            ? t("modalRequestPrize.alerts.itens")
            : t("modalRequestPrize.alerts.item")
        } ${voucherConsumed.join(", ")}.`;
        setLoading(false);
        setMessageSuccess("");
        setMessageErrorVoucher(templateMessage);
        return;
      }

      await sendEmail(emailDental, templateEmail, uId);
      setLoading(false);
      setMessageSuccess(t("modalRequestPrize.alerts.ok"));
    } catch {
      setLoading(false);
      setMessageError(t("modalRequestPrize.alerts.went_wrong"));
    }
  }

  function goToTriibo() {
    // PASSAR PARA LOGAR
    const LINK_WEB_APP_LOGADO = `${
      configJson.LINK_SITE_TRIIBO
    }/entrance/?userId=${encodeURIComponent(
      encrypt(clearCellPhone, CRYPTO_KEY)
    )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

    window.location.href = LINK_WEB_APP_LOGADO;
  }

  useEffect(() => {
    if (user.contactList !== null && user.contactList !== undefined) {
      user.contactList.forEach((item) => {
        if (item.type === "cellPhone") {
          setCellphoneValidation(item.value);
        }
      });
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [user]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          close(false);
          resetPage();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ color: "white", backgroundColor: "#10233f" }}
          id="alert-dialog-slide-title"
        >
          {t("modalRequestPrize.common.check_prize")}
          <FiX
            onClick={() => {
              close(false);
              resetPage();
            }}
            size={25}
            color={"#fff"}
            style={{ position: "absolute", right: "10", cursor: "pointer" }}
          />
        </DialogTitle>
        {messageSuccess === "" && (
          <DialogContent style={{ marginTop: "10px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <strong>{t("modalRequestPrize.common.dental")}</strong>
              {dental}
              <br />
              <br />
              <strong>
                {amountPrizes > 1
                  ? t("modalRequestPrize.alerts.prizes")
                  : t("modalRequestPrize.alerts.prize")}
              </strong>
              {product}

              {messageErrorVoucher !== "" && (
                <label
                  style={{
                    color: "red",
                    marginTop: "10px",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  {messageErrorVoucher}
                </label>
              )}
            </DialogContentText>
          </DialogContent>
        )}
        {loading ? (
          <DialogActions style={{ justifyContent: "center" }}>
            <CircularProgress size={20} style={{ color: "#42baef" }} />
          </DialogActions>
        ) : (
          <DialogActions
            style={{
              minWidth: "300px",
              minHeight: "130px",
              padding: "0 20px",
            }}
          >
            {messageError !== "" && (
              <label
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                {messageError}
              </label>
            )}
            {messageSuccess === "ok" && messageError === "" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {t("modalRequestPrize.alerts.order_successfully")}
                </h2>
                <div
                  style={{
                    maxWidth: `${originUrl.length === 0 ? "300px" : "140px"}`,
                  }}
                >
                  {originUrl.length === 0 ? (
                    <Link to="ivoclar-latam.seuclube.org">
                      <strong
                        style={{
                          marginTop: "15px",
                          color: "#646566",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {t("modalRequestPrize.buttons.return_to_triibo_app")}
                      </strong>
                    </Link>
                  ) : (
                    <Button
                      onClick={goToTriibo}
                      style={{
                        color: "#42baef",
                        fontWeight: "bold",
                        width: "180px",
                      }}
                    >
                      {t("modalRequestPrize.buttons.return_triibo")}
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => {
                    close(false);
                    resetPage();
                  }}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  {t("modalRequestPrize.buttons.change")}
                </Button>
                <Button
                  disabled={cellphoneValidation !== triiboId.split("@")[0]}
                  onClick={() => {
                    cellphoneValidation === triiboId.split("@")[0]
                      ? sendEmailDental()
                      : setMessageError(
                          "TriiboId difiere del número de contacto, póngase en contacto con nosotros."
                        );
                  }}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  {t("modalRequestPrize.buttons.completed")}
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
