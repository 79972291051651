import axios from "axios";
import { configJson } from "../firebaseUtils";
import { authTokenWithSC } from "./getSecurityCodeWithSC";

export function authCode_v1(cellPhone, sendType) {
  //OBTER INFORMAÇÕES DO USUÁRIO
  return new Promise((resolve, reject) => {
    authTokenWithSC()
      .then((token) => {
        axios
          .post(
            configJson.API_HOST_NODE + "/authCode",
            {
              cellphone: cellPhone,
              platform: "ivoclar-site",
              languageCode: "es",
              sendType,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log("RESUL_AUTH_Code ===>", result.data);

            if (result.data.success !== null) {
              resolve(result.data);
            } else {
              reject({ messageError: result.data.error.error });
              console.log(result);
            }
          })
          .catch((error) => {
            console.log("ERROR AUTH SMS ===>", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR GET CHANNEL TOKEN ===>", error);
        reject(error);
      });
  });
}
