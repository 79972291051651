import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import SlideDoubts from '../../components/SlideDoubts';
import ModalLogin from '../../components/ModalLogin';

import { ModalBuy } from '../../components/ModalBuy';
import { ModalDistribuidores } from '../../components/ModalDistribuidores';

import { checkDevice } from '../../utils/helpers';

import { useHistory } from 'react-router-dom';

import bannerDesktop from '../../assets/images/ivoclarBannerDesktop.png';
import bannerDesktop_ES from '../../assets/images/ivoclarBannerDesktop_ES.png';
import bannerMobile from '../../assets/images/ivoclarBannerMobile.png';
import bannerMobile_ES from '../../assets/images/ivoclarBannerMobile_ES.png';
import thumbFuncionamentoImg from '../../assets/images/thumbVideo1.jpg';

import funcionamentoVideo from '../../assets/videos/funcionamento-campanha.mp4';
import comoParticiparVideo from '../../assets/videos/como-participar.mp4';
import comoSolicitar from '../../assets/videos/CANJE.mp4';

import triiboLogo from '../../assets/images/logo_triibo.png';
import triiboLogo_ES from '../../assets/images/logo_triibo_ES.png';
import androidStore from '../../assets/images/loja_android_triibo.png';
import androidStore_ES from '../../assets/images/loja_android_triibo_ES.png';
import iosStore from '../../assets/images/loja_IOS_triibo.png';
import iosStore_ES from '../../assets/images/loja_IOS_triibo_ES.png';
import tutorialInfoImg from '../../assets/images/mulherSorrindo_home.png';

import Footer from '../../components/Footer';

import './home.css';
import { useTranslation } from 'react-i18next';

// PDF DE REGULAMENTO DA PROMOÇÃO FICA TRIIBO-PRD/FIREBASE/STORAGE/IVOCLAR
const LINK_PDF =
  'https://firebasestorage.googleapis.com/v0/b/project-4616920480246266429.appspot.com/o/seuClube%2FLegales%20Plataforma%20Triibos%20VI.pdf?alt=media';

const LINK_LOJA_ANDROID = 'https://triibo.page.link/1c84';
const LINK_LOJA_IOS = 'https://triibo.page.link/TWAF';
const LINK_TRIIBO = 'https://triibo.com.br';

const body = document.querySelector('body');

function Home() {
  const languageForImg = localStorage.getItem('i18nextLng').replace('-', '_');

  const { t } = useTranslation();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalHacerCompra, setShowModalHacerCompra] = useState(false);
  const [showModalDistribuidores, setShowModalDistribuidores] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [nextPage, setNextPage] = useState('');
  const [imgBannerDesktop, setImgBannerDesktop] = useState('');
  const [imgBannerMobile, setImgBannerMobile] = useState('');
  const [imgFooterTriibo, setImgFooterTriibo] = useState('');
  const [imgAndroidStore, setImgAndroidStore] = useState('');
  const [imgIosStore, setImgIosStore] = useState('');

  const history = useHistory();

  const STEP_BY_STEP = [
    { number: '1', text: t('home.steps.step_by_step_one') },
    { number: '2', text: t('home.steps.step_by_step_two') },
    { number: '3', text: t('home.steps.step_by_step_three') },
    { number: '4', text: t('home.steps.step_by_step_four') },
    { number: '5', text: t('home.steps.step_by_step_five') },
  ];

  // FUNÇÕES PARA MUDANÇAS DE IMAGENS CONFORME TRADUÇÃO
  function toggleLogoFooterTriibo() {
    if (languageForImg === 'pt_BR') {
      return triiboLogo;
    } else {
      return triiboLogo_ES;
    }
  }

  function toggleAndroidStore() {
    if (languageForImg === 'pt_BR') {
      return androidStore;
    } else {
      return androidStore_ES;
    }
  }

  function toggleIosStore() {
    if (languageForImg === 'pt_BR') {
      return iosStore;
    } else {
      return iosStore_ES;
    }
  }

  function toggleBannerDesktop() {
    if (languageForImg === 'pt_BR') {
      return bannerDesktop;
    } else {
      return bannerDesktop_ES;
    }
  }

  function toggleBannerMobile() {
    if (languageForImg === 'pt_BR') {
      return bannerMobile;
    } else {
      return bannerMobile_ES;
    }
  }
  // -----------------------------------------------

  function toggleModalLogin() {
    setShowModalLogin(!showModalLogin);
  }

  function modalHacerCompra() {
    setShowModalHacerCompra(!showModalHacerCompra);
  }

  function modalDistribuidores() {
    setShowModalDistribuidores(!showModalDistribuidores);
  }

  useEffect(() => {
    // QUANDO O MODAL ESTIVER ABERTO EVITA SCROLAR O CONTEÚDO DA PÁGINA
    body.style.overflow = showModalLogin ? 'hidden' : '';
  }, [showModalLogin]);

  useEffect(() => {
    // localStorage.clear();
    setIsMobile(checkDevice());
    window.history.pushState('', '', '/');
  }, []);

  // TROCA IMAGENS CONFORME O IDIOMA
  useEffect(() => {
    setImgBannerDesktop(toggleBannerDesktop());
    setImgBannerMobile(toggleBannerMobile());
    setImgFooterTriibo(toggleLogoFooterTriibo());
    setImgAndroidStore(toggleAndroidStore());
    setImgIosStore(toggleIosStore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageForImg]);

  return (
    <>
      <Header
        setShowModalLogin={setShowModalLogin}
        setNextPage={setNextPage}
        isShowLinks={true}
      />
      <div className='container-home' style={{ overflow: 'hidden' }}>
        <div className='image_content'>
          <img
            src={isMobile ? imgBannerMobile : imgBannerDesktop}
            alt='Imagens promoção'
          />
        </div>

        <div id='promocao' className='container-hacer-compra'>
          <img src={tutorialInfoImg} alt='Mulher sorrindo' />
          <div>
            <span>{t('home.titles.title_tutorial')}</span>
            <p style={{ display: 'inline-block', marginTop: '1rem' }}>
              {t('home.description.description_distribuidores')}
            </p>{' '}
            <button
              className='button-tutoTitle'
              onClick={() => modalDistribuidores()}>
              aquí &gt;
            </button>
            <p>{t('home.description.description_tutorial')}</p>
            <button
              className='button-tutoTitle'
              onClick={() => modalHacerCompra()}>
              compré aquí &gt;
            </button>
          </div>
        </div>

        <div className='subtitle-home'>
          <span>{t('home.titles.how_to_participate')}</span>
        </div>

        <div className='container-box'>
          <div className='content-boxTurorial'>
            <div className={'boxTutorialDarkBlue'}>
              <h1 className={'numberStepDark'}>{STEP_BY_STEP[0].number}</h1>
              <div className='textStep'>
                <span>{STEP_BY_STEP[0].text}</span>
              </div>
            </div>
          </div>

          <div className='content-boxTurorial'>
            <div className={'boxTutorialDarkBlue'}>
              <h1 className={'numberStepDark'}>{STEP_BY_STEP[1].number}</h1>
              <div className='textStep'>
                <span>{STEP_BY_STEP[1].text}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container-box'>
          <div className='content-boxTurorial'>
            <div className={'boxTutorialDarkBlue'}>
              <h1 className={'numberStepDark'}>{STEP_BY_STEP[2].number}</h1>
              <div className='textStep'>
                <span>{STEP_BY_STEP[2].text}</span>
              </div>
            </div>
          </div>

          <div className='content-boxTurorial'>
            <div className={'boxTutorialDarkBlue'}>
              <h1 className={'numberStepDark'}>{STEP_BY_STEP[3].number}</h1>
              <div className='textStep'>
                <span>{STEP_BY_STEP[3].text}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='content-boxTurorial' style={{ maxWidth: '500px' }}>
          <div className={'boxTutorialDarkBlue'}>
            <h1 className={'numberStepDark'}>{STEP_BY_STEP[4].number}</h1>
            <div className='textStep'>
              <span>{STEP_BY_STEP[4].text}</span>
            </div>
          </div>
        </div>

        <div className='timeOffert'>
          <span>
            Periodo de participación del 01 de marzo 2023 al 30 de junio de
            2023, para las personas residentes en los países de México,
            Colombia, Ecuador, Paraguay, Uruguay, Bolivia, Argentina, Guatemala
            y Costa Rica. Periodo de participación del 01 de abril 2023 al 30 de
            julio 2023, para las personas residentes en los países de Perú,
            Chile, República Dominicana.
          </span>
        </div>

        <div className='moreOffert'>
          <span>
            {t('home.description.more_offer')}{' '}
            <a
              className='btn-pdf'
              title='Regulamento'
              target='_blank'
              rel='noreferrer'
              href={LINK_PDF}>
              {t('home.description.link_pdf')}
            </a>
            .
          </span>
        </div>

        {/* CADASTRO OU LOGIN */}
        <section className='button-area'>
          <div className='content-button-home'>
            <div className='area-login-home'>
              <span>{t('home.description.is_register')}</span>
              <button
                onClick={() => {
                  toggleModalLogin();
                  setNextPage('triibo-web');
                }}>
                {t('home.buttons.label_login')}
              </button>
            </div>

            <div className='area-login-home'>
              <span>{t('home.description.subscribe_campaign')}</span>
              <button
                onClick={() => {
                  toggleModalLogin();
                  setNextPage('/cadastrar');
                }}>
                {t('home.buttons.label_subscribe')}
              </button>
            </div>
          </div>
        </section>

        {/* TUTORIAL */}
        <div className='subtitle-home'>
          <span>{t('home.titles.see_our_tutorial')}</span>
        </div>

        <div className='container-videos'>
          <div>
            <div className='timeOffert'>
              <span>
                <b>Cómo funciona la campaña</b>
              </span>
            </div>

            <div className='content-video'>
              <video controls poster={thumbFuncionamentoImg}>
                <source src={funcionamentoVideo} type='video/mp4' />
              </video>
            </div>
          </div>

          <div>
            <div className='timeOffert'>
              <span>
                <b>Cómo participar</b>
              </span>
            </div>

            <div className='content-video'>
              <video controls>
                <source src={comoParticiparVideo} type='video/mp4' />
              </video>
            </div>
          </div>

          <div>
            <div className='timeOffert'>
              <span>
                <b>Cómo canjear mi cupón</b>
              </span>
            </div>

            <div className='content-video'>
              <video controls>
                <source src={comoSolicitar} type='video/mp4' />
              </video>
            </div>
          </div>
        </div>
        <div className='timeOffert'>
          <span>{t('home.description.paragraph_see_our_tutorial')}</span>
        </div>

        <button
          className='button-dentais'
          onClick={() => history.push('/tutorial')}>
          {t('home.buttons.label_button_see_now')}
        </button>

        <div className='subtitle-home'>
          <span>{t('home.titles.frequently_asked_questions')}</span>
        </div>

        {/* CARROSSEL DE DÚVIDAS */}
        <SlideDoubts modalContacts={modalDistribuidores} />
        <Footer />

        {/* BAIXE O APP TRIIBO */}
        <div id='app' className='app-triibo'>
          <div className='are-app-triibo'>
            <a href={LINK_TRIIBO} target='_blank' rel='noreferrer'>
              <img src={imgFooterTriibo} alt='logo triibo' />
            </a>
            <div className='area-text-app'>
              <p>{t('home.footer_app_triibo.paragraph_download_app')}</p>
            </div>
            <div>
              <a
                title='android'
                target='_blank'
                rel='noreferrer'
                href={LINK_LOJA_ANDROID}>
                <img src={imgAndroidStore} alt='Android' />
              </a>
              <a
                title='ios'
                target='_blank'
                rel='noreferrer'
                href={LINK_LOJA_IOS}>
                <img src={imgIosStore} alt='Apple' />
              </a>
            </div>
          </div>
        </div>

        {/* MODAL LOGIN */}
        {showModalLogin && (
          <ModalLogin
            close={toggleModalLogin}
            setNextPage={setNextPage}
            nextPage={nextPage}
          />
        )}

        {showModalHacerCompra && <ModalBuy close={modalHacerCompra} />}
        {showModalDistribuidores && (
          <ModalDistribuidores close={modalDistribuidores} />
        )}
      </div>
    </>
  );
}

export default Home;
