// import React from "react";
import "./slideInformation.css";

import STEP_1 from "../../assets/images/step01.png";
import STEP_1_ES from "../../assets/images/step01_ES.png";
import STEP_2 from "../../assets/images/step02.png";
import STEP_2_ES from "../../assets/images/step02_ES.png";
import STEP_3 from "../../assets/images/step03.png";
import STEP_3_ES from "../../assets/images/step03_ES.png";
import { useEffect, useState } from "react";

function SlideInformation(identifyLanguage) {
  const [imgStepOne, setImgStepOne] = useState("");
  const [imgStepTwo, setImgStepTwo] = useState("");
  const [imgStepThree, setImgStepThree] = useState("");

  useEffect(() => {
    function toggleStep1() {
      if (identifyLanguage === "pt_BR") {
        return STEP_1;
      } else {
        return STEP_1_ES;
      }
    }

    function toggleStep2() {
      if (identifyLanguage === "pt_BR") {
        return STEP_2;
      } else {
        return STEP_2_ES;
      }
    }

    function toggleStep3() {
      if (identifyLanguage === "pt_BR") {
        return STEP_3;
      } else {
        return STEP_3_ES;
      }
    }

    setImgStepOne(toggleStep1());
    setImgStepTwo(toggleStep2());
    setImgStepThree(toggleStep3());
  }, [identifyLanguage]);

  return (
    <div className="container-slade-information">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="img-information">
              <img alt="img-01" src={imgStepOne} />
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-information">
              <img alt="img-02" src={imgStepTwo} />
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-information">
              <img alt="img-03" src={imgStepThree} />
            </div>
          </div>
        </div>

        <div className="carousel-indicators" style={{ width: "auto" }}>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
      </div>
    </div>
  );
}

export default SlideInformation;
