import React from "react";
import { configJson } from "../../services/firebaseUtils";
import { Button } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import "./messageRequestPrize.css";

function MessageRequestPrize({ errorApi, originUrl }) {
  const { t } = useTranslation();

  return (
    <div className="container--message-request-prize">
      {errorApi ? (
        <h1>{t("messageRequestPrize.common.went_wrong")}</h1>
      ) : (
        <>
          <h2 className="text-message--message-request-prize">
            {t("messageRequestPrize.common.havent_registered.enough")}
          </h2>
        </>
      )}
      {originUrl.length === 0 ? (
        <strong className="subtitle--message-request-prize">
          {t("messageRequestPrize.common.return_triibo_app")}
        </strong>
      ) : (
        <a
          href={configJson.LINK_VOLTAR_TRIIBO}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            style={{
              background: "#42baef",
              fontWeight: "bold",
              color: "#FFF",
            }}
          >
            {t("messageRequestPrize.common.return_triibo")}
          </Button>
        </a>
      )}
    </div>
  );
}

export default MessageRequestPrize;
