import { createContext, useEffect, useState } from "react";
import getDentalList from "../services/Apis/getDentalList";

export const DentalListContext = createContext({});

function ProviderDentalList({ children }) {
  const [dentalList, setDentalList] = useState({});

  useEffect(() => {
    const dentalListApi = async () => {
      const list = await getDentalList();
      setDentalList(list);
      // console.log("lista de dentais: ", list);
    };
    dentalListApi();
  }, []);

  const arrDentalList = Object.entries(dentalList).map((item) => {
    const [key, value] = item;
    return { key, value };
  });

  return (
    <DentalListContext.Provider value={{ dentalList, arrDentalList }}>
      {children}
    </DentalListContext.Provider>
  );
}

export default ProviderDentalList;
