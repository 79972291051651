import api from "../../utils/api";
import { configJson } from "../firebaseUtils";
import authToken from "../Apis/authToken";

export default function getCampaign(uid, campaignId, period) {
  return new Promise((resolve, reject) => {
    authToken(uid)
      .then((token) => {
        api
          .post(
            `${configJson.API_HOST_NODE}/Ivoclar/getUser`,
            {
              uid,
              campaignId,
              period,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            // console.log(result);
            return resolve(result);
          })
          .catch((error) => {
            console.log("ERROR_GET_CAMPAIGN: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}
