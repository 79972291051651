import React, { useEffect, useState, useContext } from "react";
import { FiX } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";

import { UserContext } from "../../contexts/user";
import { useTranslation, Trans } from "react-i18next";
import phoneMaskArray from "../../phoneMaskArray.json";
import InputMask from "react-input-mask";

import "./modalLogin.css";
import { randomizeNumbersString } from "../../utils/helpers";
import { ContainerSendCode, RadioButton } from "./modalLoginStyle";

const body = document.querySelector("body");

function ModalLogin({ close, nextPage, setNextPage, data }) {
  const { t } = useTranslation();
  const {
    checkUserWithCellPhone,
    sendCodeToApi,
    setCellPhoneUser,
    cellPhone,
    setCodeSms,
    loading,
    setLoading,
    setIsSendPhone,
    hasPartnerList,
    isSendPhone,
    setMessageErroCellPhone,
    messageErroCellPhone,
    setMessageErroCode,
    messageErroCode,
    message,
    setMessage,
    hasRegistration,
    setHasRegistration,
    isRegisteredUser,
    setIsRegisteredUser,
    sendCodeType,
    setSendCodeType,
  } = useContext(UserContext);

  // const [cellPhone, setCellPhone] = useState("");
  const [codeNumber, setCondeNumber] = useState("");

  const [currentCountry, setCurrentCountry] = useState("");
  const [currentDDD, setCurrentDDD] = useState("");
  const [inputField, setInputField] = useState("");
  const [inputMask, setInputMask] = useState("");
  const [fullMaskSelected, setFullMaskSelected] = useState("+55(00)00000-0000");

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(","); //transforma a string em um array a partir da vírgula
    setInputField("");
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMaskSelected(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    if (currentCountry.split(",")[0].trim() === "+55") {
      setSendCodeType({
        sms: true,
        email: false,
        whatsapp: false,
      });
    } else {
      setSendCodeType({
        sms: false,
        email: false,
        whatsapp: false,
      });
    }
  }, [currentCountry]);

  const handleInputChange = (e) => {
    setInputField(e.target.value);
    setMessageErroCellPhone("");
    setIsRegisteredUser(true);
    setMessage("");
  };

  useEffect(() => {
    setMessage("");
    setCondeNumber("");
    setMessageErroCellPhone("");
    setMessageErroCode("");
    setMessage("");
    setIsRegisteredUser(true);
    setIsSendPhone(false);
    setLoading(false);

    return () => {
      // QUANDO O MODAL FECHAR LIBERAR O HABILITA O SCROLL
      body.style.overflow = "";
    };
  }, [
    setIsRegisteredUser,
    setIsSendPhone,
    setMessage,
    setMessageErroCellPhone,
    setMessageErroCode,
    setLoading,
  ]);
  async function checkUser(event) {
    event.preventDefault();

    const isCellPhoneValid =
      cellPhone.replace(/\D/g, "").length ===
      fullMaskSelected.replace(/\D/g, "").length;

    if (isCellPhoneValid) {
      checkUserWithCellPhone(nextPage, data);
    } else {
      setLoading(false);
      setIsSendPhone(false);
      // setMessageErroCellPhone(t("modalLogin.alerts.invalid_cellphone"));
    }
  }

  useEffect(() => {
    setCellPhoneUser(
      "+" + currentDDD.replace(/\D/g, "") + inputField.replace(/\D/g, "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);
  return (
    <div className="modal-login">
      <div className="container-login">
        <div className="header-modal-login">
          {!isSendPhone ? (
            <span>{t("modalLogin.visible_in_modal.identify_yourself")}</span>
          ) : (
            <span>
              {t("modalLogin.invisible_in_modal.authentication_code")}
            </span>
          )}
          <button
            className="close"
            onClick={() => {
              close();
              // LIMPA O LOCAL_STORAGE
              localStorage.removeItem("IvoclarUser");
            }}
          >
            <FiX size={25} color={"#fff"} />
          </button>
        </div>

        <form className="form-login" onSubmit={checkUser}>
          {!isSendPhone ? (
            <>
              <br />
              {/* CONTEÚDO PARA ADICIONAR TELEFONE */}
              {messageErroCellPhone && (
                <label style={{ color: "red" }}>{messageErroCellPhone}</label>
              )}
              {message && <label style={{ color: "red" }}>{message}</label>}

              {nextPage === "triibo-web" ? (
                <span className="title-modal-login">
                  {t("modalLogin.visible_in_modal.inform_phone_number")}
                </span>
              ) : (
                <>
                  <span className="title-modal-login">
                    {t("modalLogin.register_user_in_modal.inform_data")}
                  </span>

                  <span>
                    <Trans i18nKey="modalLogin.register_user_in_modal.description_informations" />
                  </span>
                </>
              )}

              <label className="label-input-modal-login">
                {t("modalLogin.visible_in_modal.phone")}
              </label>
              <div className="inputs-modal-login-container">
                <select value={currentCountry} onChange={handleCountryChange}>
                  <option disabled value="">
                    País
                  </option>
                  {phoneMaskArray.map((country) => (
                    <option
                      key={country.id}
                      value={[country.ddd, country.mask, country.fullMask]}
                    >
                      {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
                <InputMask
                  disabled={currentDDD === ""}
                  mask={inputMask}
                  value={inputField}
                  placeholder={randomizeNumbersString(inputMask)}
                  onChange={handleInputChange}
                />
              </div>
              {currentCountry.split(",")[0].trim() === "+55" ||
              currentCountry.split(",")[0].trim().length === 0 ? null : (
                <ContainerSendCode>
                  <span>
                    {t("modalLogin.invisible_in_modal.receive_code_title")}
                  </span>
                  <span>
                    <RadioButton
                      type="button"
                      isactive={sendCodeType.sms === true}
                      activecolor="ultraBlue"
                      onClick={() =>
                        setSendCodeType({
                          ...sendCodeType,
                          sms: true,
                          whatsapp: false,
                        })
                      }
                    >
                      SMS
                    </RadioButton>
                    <RadioButton
                      type="button"
                      isactive={sendCodeType.whatsapp === true}
                      activecolor="ultraBlue"
                      onClick={() =>
                        setSendCodeType({
                          ...sendCodeType,
                          whatsapp: true,
                          sms: false,
                        })
                      }
                    >
                      Whatsapp
                    </RadioButton>
                  </span>
                </ContainerSendCode>
              )}
              {nextPage === "triibo-web" ? (
                <button
                  className="button-login"
                  disabled={
                    inputField.replace(/\D/g, "").length <
                      inputMask.replace(/\D/g, "").length || loading
                  }
                  type="submit"
                  style={{
                    cursor: `${
                      inputField.replace(/\D/g, "").length !==
                      inputMask.replace(/\D/g, "").length
                        ? "no-drop"
                        : "pointer"
                    }`,
                  }}
                  onClick={(e) => {
                    checkUser(e);
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    t("modalLogin.button.label_next")
                  )}
                </button>
              ) : (
                <button
                  className="button-login"
                  disabled={
                    inputField.replace(/\D/g, "").length <
                      inputMask.replace(/\D/g, "").length || loading
                  }
                  type="submit"
                  style={{
                    cursor: `${
                      inputField.replace(/\D/g, "").length !==
                      inputMask.replace(/\D/g, "").length
                        ? "no-drop"
                        : "pointer"
                    }`,
                  }}
                  onClick={(e) => {
                    checkUser(e);
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    t("modalLogin.button.label_next")
                  )}
                </button>
              )}

              {!hasPartnerList &&
                isRegisteredUser &&
                nextPage === "triibo-web" &&
                messageErroCellPhone !== "" && (
                  <button
                    type="button"
                    className="button-nav-login"
                    onClick={() => {
                      setMessage("");
                      setMessageErroCellPhone("");
                      setNextPage("/cadastrar");
                    }}
                  >
                    {t(
                      "modalLogin.invisible_in_modal.not_participating_campaing"
                    )}{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontSize: "inherit",
                      }}
                    >
                      {t("modalLogin.invisible_in_modal.link_inscribe")}
                    </span>
                  </button>
                )}

              {!isRegisteredUser && nextPage === "triibo-web" && (
                <button
                  type="button"
                  className="button-nav-login"
                  onClick={() => {
                    setMessage("");
                    setNextPage("/cadastrar");
                  }}
                >
                  {t("modalLogin.invisible_in_modal.dont_have_account")}
                </button>
              )}
            </>
          ) : (
            <>
              {/* CONTEÚDO PARA ADICIONAR SMS */}
              {messageErroCode && (
                <label style={{ color: "red" }}>{messageErroCode}</label>
              )}
              <span className="title-modal-login">
                {t("modalLogin.phone_authentication.description_code")}
              </span>

              <label className="label-input-modal-login">
                {t("modalLogin.phone_authentication.code")}
              </label>
              <input
                value={codeNumber}
                onChange={(event) => {
                  setCondeNumber(event.target.value);
                  setCodeSms(event.target.value);
                  setMessageErroCode("");
                }}
                type="tel"
                name="code_number"
                placeholder="000000"
                maxLength="6"
                autoComplete="off"
              />

              <button
                disabled={codeNumber === "" || loading}
                style={{
                  cursor: `${codeNumber === "" ? "no-drop" : "pointer"}`,
                }}
                type="submit"
                className="button-login"
                onClick={() => sendCodeToApi(codeNumber)}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  t("modalLogin.button.label_next")
                )}
              </button>

              {!hasRegistration && !loading && (
                <>
                  <span
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#646566",
                      margin: "0",
                    }}
                    onClick={() => {
                      setIsSendPhone(false);
                      setCondeNumber("");
                      setMessageErroCode("");
                      setHasRegistration(false);
                    }}
                  >
                    {t(
                      "modalLogin.phone_authentication.not_received_code_start"
                    )}{" "}
                    <span
                      style={{ color: "#646566", textDecoration: "underline" }}
                    >
                      {t(
                        "modalLogin.phone_authentication.not_received_code_end"
                      )}
                    </span>
                  </span>
                  <span>
                    {t("modalLogin.phone_authentication.whatsapp_request")}
                    <a
                      href="https://api.whatsapp.com/send?phone=5511944965799&text=Hola%2C%20necesito%20ayuda"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "#4bc858",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                    >
                      Whatsapp.
                    </a>
                  </span>
                </>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default ModalLogin;
