import CryptoJS from "crypto-js";
import md5 from "../utils/md5";

export function validateRegistrationDate(registrationDate) {
  try {
    const mkTime = new Date(registrationDate).getTime() / 1000.0;
    const mkTimeCurrent = new Date().getTime() / 1000.0;
    const date = Math.floor((mkTimeCurrent - mkTime) / 60 / 60);
    if (date > 20 || registrationDate === null) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
}

export function hashTriibo1(apiToken, passPhrase, channelToken) {
  let challenge1 = passPhrase + apiToken; //Passo 1: String a apiToken + passPhrase
  challenge1 = md5.hexMD5(challenge1); //Passo 2: challenge1 ou challenge intermediário

  let challenge2 = challenge1 + channelToken; //Passo 3: String a challenge1 + channelToken - sendo este valor o do ITEM B ou ex: keychannelclubmapfre (é a sua Private KEY)
  challenge2 = md5.hexMD5(challenge2);

  return challenge2;
}

export function findIndexInArray(array, type, value) {
  let x = null;

  if (Array.isArray(array)) {
    array.map(function (array, i) {
      if (array !== null && array[type] === value) {
        x = i;
      }

      return x;
    });
  }

  return x;
}

export function ValidateCpf(strCPF) {
  let Soma;
  let Resto;
  Soma = 0;

  if (
    strCPF.length !== 11 ||
    !Array.from(strCPF).filter((e) => e !== strCPF[0]).length
  ) {
    return false;
  }

  if (strCPF === "00000000000") {
    return false;
  }

  for (var i = 1; i <= 9; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
  }

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
  }

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }

  return true;
}

export function checkDevice() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true; // está utilizando celular
  } else {
    return false; // não é celular
  }
}

export function decrypt(msg, key) {
  if (!msg) return null;
  msg = decodeURIComponent(msg);
  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse("0000000000000000"),
  };
  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };
  let json = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(msg),
    },
    myEncrypt.pass,
    options
  );
  return json.toString(CryptoJS.enc.Utf8);
}

export function encrypt(msg, key) {
  if (!msg) return null;
  const myEncrypt = {
    pass: CryptoJS.enc.Utf8.parse(key),
    iv: CryptoJS.enc.Hex.parse("0000000000000000"),
  };
  let options = {
    mode: CryptoJS.mode.CBC,
    iv: myEncrypt.iv,
  };
  let json = CryptoJS.AES.encrypt(msg, myEncrypt.pass, options);
  return json.ciphertext.toString(CryptoJS.enc.Base64);
}

export const randomizeNumbersString = (str) => {
  let newStr = str.replace(/[0-9]/g, `${Math.floor(Math.random() * 10)}`);

  return newStr;
};
