import { FiX } from "react-icons/fi";

import "./modalBuy.css";
import { TableContactos } from "./table";

export function ModalBuy({ close }) {
  return (
    <div className="modal-hacer-compra">
      <div className="content-hacer-compra">
        <div className="header-hacer-compra">
          <span>Póngase en contacto con nosotros</span>
          <button className="close" onClick={() => close()}>
            <FiX size={25} color={"#fff"} />
          </button>
        </div>
        <TableContactos />
      </div>
    </div>
  );
}
