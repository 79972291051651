import React from "react";
import { Checkbox, CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import InputMask from "react-input-mask";
import styled from "styled-components";
import phoneMaskArray from "../../../phoneMaskArray.json";
import { randomizeNumbersString } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const CustomTextField = styled(TextField)`
  label.Mui-focused {
    color: green;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: transparent;
      color: #000;
    }
    &:hover fieldset {
      border-color: transparent;
    }
    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
`;

export default function Form({
  handleSubmit,
  setMessageErrorCellPhone,
  hasPrize,
  messageErrorCellPhone,
  currentCountry,
  handleCountryChange,
  currentDDD,
  inputMask,
  inputField,
  handleInputChange,
  loading,
  totalPrize,
  partnerDataWinner,
  additionalInfoWinner,
  cellPhone,
  messagePrize,
  vouchers,
  listProducts,
  checked,
  handleSelectedPrize,
  handleChecked,
  vouchersUnrequised,
  winnerHasCupons,
  checkedUnsolicited,
  handleSelectedPrizeUnsolicited,
  handleCheckedUnsolicited,
  handleForm,
}) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit}
      onChange={() => setMessageErrorCellPhone("")}
      className="form-give-prize">
      {!hasPrize ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}>
          {!hasPrize && (
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <h1 className="title-give-prize">
                {t("givePrize.common.title")}
              </h1>
              <p>{t("givePrize.common.description")}</p>
            </div>
          )}
          <div className="area-input-give-prize">
            {messageErrorCellPhone && (
              <label className="text-error-give-prize">
                {messageErrorCellPhone}
              </label>
            )}
            <label className="label-input-give">
              {t("givePrize.labels.cellphone")}
            </label>
            <div className="inputs-container">
              <select value={currentCountry} onChange={handleCountryChange}>
                <option disabled value="">
                  País
                </option>
                {phoneMaskArray.map((country, index) => (
                  <option
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}>
                    {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
              <InputMask
                className="container-give-prize-input"
                disabled={currentDDD === ""}
                mask={inputMask}
                value={inputField}
                placeholder={randomizeNumbersString(inputMask)}
                onChange={handleInputChange}
              />
            </div>
            <button
              disabled={loading}
              className="button-give-prize"
              type="submit">
              {loading ? (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              ) : (
                t("givePrize.buttons.search")
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="area-form-give-prize-02">
          <h1 style={{ marginTop: "20%" }}>{t("givePrize.common.title")}</h1>
          <p>
            {" "}
            {t("givePrize.common.client_is_entitled")} {totalPrize} IPS e.max{" "}
          </p>

          <div className="content-contact-client">
            <div className="item-contact-client">
              <span>
                <strong className="bold-label-give-prize">
                  {t("givePrize.common.operator_name")}{" "}
                </strong>
                {partnerDataWinner.name} {partnerDataWinner.lastName}
              </span>
              <span>
                <strong className="bold-label-give-prize">
                  {t("givePrize.common.stablishment_name")}{" "}
                </strong>
                {additionalInfoWinner.entityType === "Laboratorio"
                  ? additionalInfoWinner.corporateName
                  : `${additionalInfoWinner.name} ${additionalInfoWinner.lastName}`}
              </span>
            </div>

            <div className="item-contact-client">
              <span>
                <strong className="bold-label-give-prize">
                  {t("givePrize.common.cellphone_operator")}{" "}
                </strong>
                {cellPhone}
              </span>
            </div>
          </div>

          <h2 style={{ marginTop: "30px" }}>
            {t("givePrize.common.request_dental")}
          </h2>

          {messagePrize !== "" && (
            <span style={{ color: "red", fontSize: "13px" }}>
              {messagePrize}
            </span>
          )}

          {vouchers.length === 0 ? (
            <div className="area-text-info-gize-prize">
              <p>{t("givePrize.common.there_not_request")}</p>
            </div>
          ) : (
            vouchers.map((coupon, inputIndex) => {
              return (
                <div
                  className="container-preview-prizes--give-prive"
                  key={inputIndex}>
                  <div key={inputIndex} className="area-selected-give-prize">
                    {/* INPUT DE PESQUISA */}
                    <div className="content-input-cod-give-prize">
                      <label className="label-preview--give-prize">
                        {`Cupom: ${inputIndex + 1}`}{" "}
                      </label>
                      <input
                        value={coupon.key}
                        style={{ alignSelf: "flex-start" }}
                        type="text"
                        disabled
                        className="input-code-number-give-prize"
                      />
                    </div>

                    <div className="select-prize--give-prize">
                      <label className="label-preview--request-prize">
                        {t("givePrize.common.product")}{" "}
                      </label>
                      <Autocomplete
                        id="field_0"
                        options={listProducts}
                        disabled={!checked[inputIndex]}
                        noOptionsText="Produto não encontrado"
                        disableClearable={true}
                        getOptionLabel={(option) => option.promotion.title}
                        onChange={(_, newValue) => {
                          if (newValue !== null) {
                            handleSelectedPrize(
                              newValue,
                              inputIndex,
                              coupon.key
                            );
                          }
                        }}
                        renderOption={(option) => option.promotion.title}
                        className="select-prize--give-prize"
                        renderInput={(params) => (
                          <CustomTextField
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            variant="outlined"
                            placeholder={t(
                              "givePrize.placeholders.find_product"
                            )}
                            size="small"
                            autoComplete="off"
                            autoCorrect="off"
                            id="field1"
                          />
                        )}
                      />
                    </div>

                    <div className="content-checkbox-area-give-prize">
                      <Checkbox
                        checked={checked[inputIndex]}
                        onChange={() => {
                          handleChecked(!checked[inputIndex], inputIndex);
                        }}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        style={{ color: "#42baef" }}
                      />
                      <span className="checkbox-text-prize">
                        {t("givePrize.common.deliver_now")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}

          {vouchersUnrequised.length > 0 && winnerHasCupons && (
            <h2 style={{ marginTop: "30px" }}>
              {t("givePrize.common.client_not_redemption")}{" "}
            </h2>
          )}

          {vouchersUnrequised.length > 0 &&
            winnerHasCupons &&
            vouchersUnrequised.map((coupon, inputIndex) => {
              return (
                <div
                  className="container-preview-prizes--give-prive"
                  key={inputIndex}>
                  <div key={inputIndex} className="area-selected-give-prize">
                    {/* INPUT DE PESQUISA */}
                    <div className="content-input-cod-give-prize">
                      <label className="label-preview--request-prize">
                        {`Cupom: ${inputIndex + 1}`}{" "}
                      </label>
                      <input
                        value={coupon.key}
                        style={{ alignSelf: "flex-start" }}
                        type="text"
                        disabled
                        className="input-code-number-give-prize"
                      />
                    </div>

                    <div className="select-prize--give-prize">
                      <label className="label-preview--request-prize">
                        {t("givePrize.common.product")}
                      </label>
                      <Autocomplete
                        id="field_0"
                        options={listProducts}
                        disabled={!checkedUnsolicited[inputIndex]}
                        noOptionsText="Produto não encontrado"
                        disableClearable={true}
                        getOptionLabel={(option) => option.promotion.title}
                        onChange={(_, newValue) => {
                          if (newValue !== null) {
                            handleSelectedPrizeUnsolicited(
                              newValue,
                              inputIndex,
                              coupon.key
                            );
                          }
                        }}
                        renderOption={(option) => option.promotion.title}
                        className="select-prize--give-prize"
                        renderInput={(params) => (
                          <CustomTextField
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            variant="outlined"
                            placeholder={t(
                              "givePrize.placeholders.find_product"
                            )}
                            size="small"
                            autoComplete="off"
                            autoCorrect="off"
                            id="field1"
                          />
                        )}
                      />
                    </div>

                    <div className="content-checkbox-area-give-prize">
                      <Checkbox
                        checked={checkedUnsolicited[inputIndex]}
                        onChange={() => {
                          handleCheckedUnsolicited(
                            !checkedUnsolicited[inputIndex],
                            inputIndex
                          );
                        }}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        style={{ color: "#42baef" }}
                      />
                      <span className="checkbox-text-prize">
                        {t("givePrize.common.deliver_now")}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="area-text-info-gize-prize">
            {/* <p>Texto de aviso para a dental.</p> */}
          </div>

          <button
            style={{
              backgroundColor: `${
                vouchers.length === 0 && winnerHasCupons === false
                  ? "#b6e1f3"
                  : "#42baef"
              }`,
              cursor: `${
                vouchers.length === 0 && winnerHasCupons === false
                  ? "no-drop"
                  : "pointer"
              }`,
            }}
            disabled={vouchers.length === 0 && winnerHasCupons === false}
            className="button-give-prize"
            onClick={handleForm}
            type="button">
            {t("givePrize.buttons.confirm_award")}
          </button>
        </div>
      )}
    </form>
  );
}
