import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FiX } from "react-icons/fi";
import { CircularProgress } from "@material-ui/core";

import { apiOrder } from "../../services/Apis/apiOrder";
import { voucherSend_v1 } from "../../services/Apis/voucherSend_v1";
import { configJson } from "../../services/firebaseUtils";
import { voucherLogSendPrize } from "../../services/Apis/voucherLog";
import { encrypt } from "../../utils/helpers";

import { useTranslation } from "react-i18next";

const CRYPTO_KEY = configJson.CRYPTO_KEY;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalGivePrize({
  close,
  open,
  cellPhone,
  triiboIdDental,
  product,
  itemId,
  templatId,
  storesIds,
  uIdDental,
  uIdWinner,
  stablishmentId,
}) {
  const { t } = useTranslation();

  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");
  const [originUrl, setOriginUrl] = useState("");

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const receiver = `+${cellPhone.replace(/\D/g, "")}@sms,triibo,com,br`;

  useEffect(() => {
    const referrer = document.referrer;
    setOriginUrl(referrer);

    setLoading1(false);
    setLoading2(false);
    setLoading3(false);

    setMessageSuccess("");
    setMessageError("");
  }, []);

  async function consumerVoucher() {
    setLoading1(true);

    const clearTriiboId = `${triiboIdDental.trim().replace(/[.]/gi, ",")}`;

    const promiseAll = Promise.all(
      itemId.map((item) => apiOrder(item, clearTriiboId, receiver))
    )
      .then((result) => {
        setMessageSuccess(t("modalGivePrize.alerts.completed"));
        setLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading1(false);
        setMessageError(t("modalGivePrize.alerts.went_wrong"));
        setMessageSuccess("");
      });
    return promiseAll;
  }

  async function sendVoucher() {
    setLoading2(true);

    const clearTemplateId = templatId.filter(
      (template) => template !== "" && template !== undefined
    );

    const promiseAll = Promise.all(
      clearTemplateId.map((item) => voucherSend_v1(item, receiver))
    )
      .then((result) => {
        setLoading2(false);
        setMessageSuccess(t("modalGivePrize.alerts.completed"));
      })
      .catch((error) => {
        console.log(error);
        setLoading2(false);
        setMessageError(t("modalGivePrize.alerts.went_wrong"));
        setMessageSuccess("");
      });
    return promiseAll;
  }

  async function LogSendPrize() {
    setLoading3(true);
    const clearStoreId = storesIds.filter(
      (storeId) => storeId !== "" && storeId !== undefined
    );

    voucherLogSendPrize(uIdDental, uIdWinner, clearStoreId, stablishmentId)
      .then(() => {
        setMessageSuccess(t("modalGivePrize.alerts.completed"));
        setLoading3(false);
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
        setMessageError(t("modalGivePrize.alerts.went_wrong"));
        setMessageSuccess("");
      });
  }

  function goToTriibo() {
    const clearCellPhone = triiboIdDental.slice(0, 14);

    // PASSAR PARA LOGAR
    const LINK_WEB_APP_LOGADO = `${
      configJson.LINK_SITE_TRIIBO
    }/entrance/?userId=${encodeURIComponent(
      encrypt(clearCellPhone, CRYPTO_KEY)
    )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

    window.location.href = LINK_WEB_APP_LOGADO;
  }

  function searchNewUser() {
    window.location.reload();
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => (messageSuccess === "" ? close(false) : searchNewUser)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            color: "white",
            backgroundColor: "#10233f",
            minWidth: "320px",
          }}
          id="alert-dialog-slide-title"
        >
          {t("modalGivePrize.alerts.confirm_the_prize")}
          <FiX
            onClick={() =>
              messageSuccess === "" ? close(false) : searchNewUser
            }
            size={25}
            color={"#fff"}
            style={{ position: "absolute", right: "10", cursor: "pointer" }}
          />
        </DialogTitle>
        {messageSuccess === "" && (
          <DialogContent style={{ marginTop: "10px" }}>
            <DialogContentText id="alert-dialog-slide-description">
              <strong>{itemId.length > 1 ? "Premios: " : "Premio: "}</strong>
              {product}
            </DialogContentText>
          </DialogContent>
        )}
        {(loading1 || loading2 || loading3) && messageSuccess !== "" ? (
          <DialogActions
            style={{
              justifyContent: "center",
              minWidth: "300px",
              minHeight: "150px",
            }}
          >
            <CircularProgress size={20} style={{ color: "#42baef" }} />
          </DialogActions>
        ) : (
          <>
            {messageSuccess !== "" ? (
              <DialogActions
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    marginBottom: "30px",
                  }}
                >
                  {messageSuccess}
                </span>
                <Button
                  onClick={searchNewUser}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  {t("modalGivePrize.buttons.search_new_user")}
                </Button>
                <Button
                  onClick={goToTriibo}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  {t("modalGivePrize.buttons.return_triibo")}
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                {messageError !== "" && (
                  <label
                    style={{
                      color: "red",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >
                    {messageError}
                  </label>
                )}
                <Button
                  onClick={() => close(false)}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                >
                  {t("modalGivePrize.buttons.change")}
                </Button>
                <Button
                  onClick={() => {
                    consumerVoucher();
                    sendVoucher();
                    LogSendPrize();
                  }}
                  style={{
                    color: "#42baef",
                    fontWeight: "bold",
                  }}
                  disabled={loading1 || loading2 || loading3}
                >
                  {t("modalGivePrize.buttons.completed")}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}
