import axios from 'axios';
import { configJson } from '../firebaseUtils';
import { validateRegistrationDate } from '../../utils/helpers';

export default async function authToken(uIdUrl) {
  return new Promise(async (resolve, reject) => {
    //PEGA O TOKEN DO LOCAL_STORAGE
    const token = JSON.parse(localStorage.getItem('ivoclarToken'));
    //PEGA OS DADOS DO USUÁRIO LOCAL_STORAGE
    // const user = JSON.parse(localStorage.getItem("IvoclarUser"));
    let auth = window.btoa(configJson.username + ':' + configJson.password);

    let config = {
      baseURL: configJson.API_HOST_NODE,
      method: 'post',
      // auth: { username: configJson.username, password: configJson.password },
      headers: {
        Authorization: 'Basic ' + auth,
      },
    };

    if (uIdUrl) {
      config.headers.uid = uIdUrl;
    }

    const api = axios.create(config);

    //VERIFICA SE O TOKEN AINDA É VALIDO
    const valideDate = validateRegistrationDate(token?.date);
    if (
      token === null ||
      token === undefined ||
      !valideDate ||
      uIdUrl !== token.uId
    ) {
      api('auth')
        .then((result) => {
          // console.log("RESUL_AUTH_TOKEN: ", result.data.token);
          // SALVA O TOKEN E A DATA ATUAL NO LOCAL_STORAGE
          localStorage.setItem(
            'ivoclarToken',
            JSON.stringify({
              token: result.data.token,
              date: Date.now(),
              uId: uIdUrl,
            })
          );
          resolve(result.data.token);
        })
        .catch((error) => {
          console.log('RESUL_AUTH_TOKEN: ', error);
          reject(error);
        });
    } else {
      resolve(token.token);
    }
  });
}
