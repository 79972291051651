import api from "../../utils/api";
import { configJson } from "../firebaseUtils";
import authToken from "./authToken";

export default function getDentalList() {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        api
          .get(
            `${configJson.API_HOST_NODE}/campaign/partner?campaignId=-campaignIvoclarI03`,
            {
              headers: {
                origin: configJson.API_HOST_NODE,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            return resolve(result.data);
          })
          .catch((error) => {
            console.log("ERROR_GET_DENTAL_LIST: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}
