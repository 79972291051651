import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { premios } from "../../constants/productsList";
import { useTranslation } from "react-i18next";

import "./slideDoubts.css";

function SlideDoubts({ modalContacts }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const DUVIDAS = [
    {
      title: t("slideDoubts.titles.who_can_participate"),
      text: t("slideDoubts.text.description_who_can_participate"),
    },
    {
      title: t("slideDoubts.titles.how_to_participate"),
      text: (
        <ol>
          <li>
            El cliente deberá registrase a través del sitio web:{" "}
            <a
              style={{ color: "#000", fontWeight: 600 }}
              href="https://ivoclar-latam.triibo.org/"
            >
              https://ivoclar-latam.triibo.org/
            </a>
          </li>
          <li>
            Al realizar cada compra con los distribuidores participantes
            recibirá un cupón.
          </li>
          <li>
            El cupón lo ingresará en la sección registre su compra dentro del
            sitio web junto con su factura. 
          </li>
          <li>
            Por cada 10 cupones registrados será acreedor de un tubo adicional. 
          </li>
        </ol>
      ),
    },
    {
      title: t("slideDoubts.titles.period_of_participation"),
      text: "Periodo de participación del 01 de marzo 2023 al 30 de junio de 2023, para las personas residentes en los países de México, Colombia, Ecuador, Paraguay, Uruguay, Bolivia, Argentina, Guatemala y Costa Rica. Periodo de participación del 01 de  abril 2023  al 30 de julio 2023, para las personas residentes en los países de Perú,Chile, República Dominicana. Conoce a los",
    },
    {
      title: t("slideDoubts.titles.did_not_get_the_coupon"),
      text: t("slideDoubts.text.description_did_not_get_the_coupon"),
    },
    {
      title: t("slideDoubts.titles.products_can_i_order"),
      text: t("slideDoubts.text.introduction_description_products_can_i_order"),
    },
    {
      title: t("slideDoubts.titles.what_products_can_i_register"),
      text: t("slideDoubts.text.description_what_products_can_i_register"),
    },
    {
      title: t("slideDoubts.titles.who_is_the_triibo"),
      text: t("slideDoubts.text.description_who_is_the_triibo"),
    },
    {
      title: t("slideDoubts.titles.how_to_redeem_the_prize"),
      text: t("slideDoubts.text.description_how_to_redeem_the_prize"),
    },
    {
      title: t("slideDoubts.titles.how_to_contact"),
      text: "Cualquier consulta, puedes contactarmos por el icono del Whatsapp encontrado en esta página.",
    },
  ];

  useEffect(() => {
    // RESETA O SCROLL DA LISTA DE PRODUTOS
    const resetSrcoll = document.querySelector("#product-list");
    resetSrcoll.scrollTop = 0;
  }, [expanded]);

  return (
    <div className="container-slade-doubts">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-inner">
          <div
            style={{ marginBottom: "20px" }}
            className="carousel-item active"
          >
            {/* PART 1 DO CARROSSEL  */}
            <section className="accordion">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[0].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[0].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[1].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[1].text} </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[2].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {DUVIDAS[2].text}{" "}
                    <a
                      href="#"
                      style={{ color: "#000", fontWeight: 600 }}
                      onClick={modalContacts}
                    >
                      distribuidores participantes.
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </section>
          </div>

          {/* PART 2 DO CARROSSEL */}
          <section style={{ marginBottom: "20px" }} className="carousel-item">
            <div className="accordion">
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[3].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[3].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[4].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="product-list"
                    style={{
                      maxHeight: "400px",
                      overflow: "auto",
                      fontWeight: "bold",
                    }}
                  >
                    {DUVIDAS[4].text}
                    <br />
                    <br />
                    {premios.map((dental, index) => {
                      return (
                        <div key={index}>
                          <p>- {dental.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[5].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[5].text}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </section>

          {/* PART 3 DO CARROSSEL */}
          <section style={{ marginBottom: "20px" }} className="carousel-item">
            <div className="accordion">
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[6].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <span>{DUVIDAS[6].text}</span>{" "}
                    <a
                      title="Triibo"
                      target="_blank"
                      rel="noreferrer"
                      href="https://triibo.com.br/"
                      style={{ color: "#000" }}
                    >
                      https://triibo.com.br/
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[7].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[7].text}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
                className="accordion-item-slide-doubts"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span>{DUVIDAS[8].title}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{DUVIDAS[8].text}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </section>
        </div>
      </div>

      {/* BOTOES DE CONTROLE DO CARROSSEL */}
      <div className="content-carousel-control">
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default SlideDoubts;
