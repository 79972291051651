import React, { useEffect, useContext, useState } from "react";

import { UserContext } from "../../contexts/user";
import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import "./information.css";

import arrowNextImg from "../../assets/images/right-arrow.svg";
import arrowPrevImg from "../../assets/images/left-arrow.svg";

import loja_android from "../../assets/images/loja-android.png";
import loja_android_ES from "../../assets/images/loja-android_ES.png";
import loja_ios from "../../assets/images/loja-ios.png";
import loja_ios_ES from "../../assets/images/loja-ios_ES.png";

import SlideInformation from "../../components/SlideInformation";

const LINK_LOJA_ANDROID = "https://triibo.page.link/1c84";
const LINK_LOJA_IOS = "https://triibo.page.link/TWAF";

const CRYPTO_KEY = configJson.CRYPTO_KEY;

function Information() {
  localStorage.setItem("i18nextLng", navigator.language);

  const { t } = useTranslation();
  const languageForImg = localStorage.getItem("i18nextLng").replace("-", "_");

  const { cellPhone } = useContext(UserContext);
  const history = useHistory();

  const [imgAndroidLogo, setImgAndroidLogo] = useState("");
  const [imgIosLogo, setImgIosLogo] = useState("");

  useEffect(() => {
    // ALTERAÇÕES DE IMAGENS DE TRADUCAO
    function toggleLogoAndroid() {
      if (languageForImg === "pt_BR") {
        return loja_android;
      } else {
        return loja_android_ES;
      }
    }

    function toggleLogoIos() {
      if (languageForImg === "pt_BR") {
        return loja_ios;
      } else {
        return loja_ios_ES;
      }
    }

    setImgAndroidLogo(toggleLogoAndroid());
    setImgIosLogo(toggleLogoIos());
  }, [languageForImg]);

  // PASSAR PARA LOGAR
  const LINK_WEB_APP_LOGADO = `${
    configJson.LINK_SITE_TRIIBO
  }/entrance/?userId=${encodeURIComponent(
    encrypt(cellPhone, CRYPTO_KEY)
  )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // LIMPA O LOCAL_STORAGE
  useEffect(() => {
    localStorage.clear();
  }, []);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    if (!cellPhone) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-information">
      <main className="main-infomation">
        <h1 className="title-information">{t("information.common.title")}</h1>
        <p>{t("information.common.description_one")}</p>
        <p>{t("information.common.description_two")}</p>
        <div
          className="button-login-link"
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "480px",
            alignSelf: "center",
            marginTop: "1rem",
            marginBottom: "1.5rem",
          }}>
          <a
            title="Triibo"
            rel="noreferrer"
            href={LINK_WEB_APP_LOGADO}
            style={{ color: "#fff" }}>
            {t("information.common.link_continue")}
          </a>
        </div>

        {/* LINHA DE DIVISÃO */}
        <div className="lineDivider" />
        <h1 className="title-info">
          {t("information.common.how_to_register_product")}
        </h1>
        <div
          className="content-buttons-carousel"
          style={{ marginBottom: "3rem" }}>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            className="btn-control-carousel">
            <img className="prev-carousel" src={arrowPrevImg} alt="" />
          </button>
          {/* CARROSSEL */}
          <SlideInformation identifyLanguage={languageForImg} />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            className="btn-control-carousel">
            <img className="next-carousel" src={arrowNextImg} alt="" />
          </button>
        </div>

        <p className="text-botton">
          {t("information.common.information_access_app")}
        </p>
        <div className="img-area">
          <a
            title="android"
            target="_blank"
            rel="noreferrer"
            href={LINK_LOJA_ANDROID}>
            <img
              style={{
                height: "37px",
                marginRight: "2.5rem",
                marginBottom: "2rem",
              }}
              src={imgAndroidLogo /* loja_android */}
              alt="loja android"
            />
          </a>
          <a title="ios" target="_blank" rel="noreferrer" href={LINK_LOJA_IOS}>
            <img src={imgIosLogo /* loja_ios */} alt="loja ios" />
          </a>
        </div>
      </main>
    </div>
  );
}

export default Information;
