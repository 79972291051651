import React from "react";
import "./footer.css";
import { AiOutlineFacebook } from "react-icons/ai";
import { FiInstagram, FiYoutube, FiLinkedin, FiTwitter } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const LINKS_HEADER = {
  fornecedores: "https://www.ivoclar.com/es_latam/legal/suppliers",
  protecao_de_dados: "https://www.ivoclar.com/es_latam/legal/privacy-policy",
  imprint: "https://www.ivoclar.com/es_latam/imprint",
  cookies: "https://www.ivoclar.com/es_latam/shared/cookies-declaration",
  termos_codicao:
    "https://www.ivoclar.com/es_latam/legal/general-terms-and-conditions",
};

const LINKS_SOCIAL_MEDIA = {
  facebook: "https://www.facebook.com/Ivoclar.lac",
  youtube: "https://www.youtube.com/c/Ivoclar-global",
  twitter: "https://twitter.com/Ivoclar",
  instagram: "https://www.instagram.com/ivoclar/",
  linkedin: "https://www.linkedin.com/company/ivoclar-latam",
};

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer-home">
      <div className="content-links-footer">
        <nav className="nav-footer">
          <a
            title="impressao"
            target="_blank"
            rel="noreferrer"
            href={LINKS_HEADER["fornecedores"]}
            className="link-footer">
            {t("footer.providers")}
          </a>

          <a
            title="impressao"
            target="_blank"
            rel="noreferrer"
            href={LINKS_HEADER["protecao_de_dados"]}
            className="link-footer">
            {t("footer.data_protection")}
          </a>
          <a
            title="Termos e condições"
            target="_blank"
            rel="noreferrer"
            href={LINKS_HEADER["imprint"]}
            className="link-footer">
            {t("footer.imprint")}
          </a>
          <a
            title="Informação protegida"
            target="_blank"
            rel="noreferrer"
            href={LINKS_HEADER["cookies"]}
            className="link-footer">
            Cookies
          </a>
          <a
            title="Informação protegida"
            target="_blank"
            rel="noreferrer"
            href={LINKS_HEADER["termos_codicao"]}
            className="link-footer">
            {t("footer.terms_and_conditions")}
          </a>
        </nav>

        <div className="area-links-footer">
          <div className="iconArea">
            <a
              title="instagram"
              target="_blank"
              rel="noreferrer"
              href={LINKS_SOCIAL_MEDIA["instagram"]}>
              <FiInstagram />
            </a>
            <a
              title="facebook"
              target="_blank"
              rel="noreferrer"
              href={LINKS_SOCIAL_MEDIA["facebook"]}>
              <AiOutlineFacebook />
            </a>
            <a
              title="youtube"
              target="_blank"
              rel="noreferrer"
              href={LINKS_SOCIAL_MEDIA["youtube"]}>
              <FiYoutube />
            </a>
            <a
              title="Acessar"
              target="_blank"
              rel="noreferrer"
              href={LINKS_SOCIAL_MEDIA["linkedin"]}>
              <FiLinkedin />
            </a>
            <a
              title="twitter"
              target="_blank"
              rel="noreferrer"
              href={LINKS_SOCIAL_MEDIA["twitter"]}>
              <FiTwitter />
            </a>
          </div>
        </div>
      </div>

      <div className="content-text-created-by">
        <span className="created-by-text">© 2022 Ivoclar Vivadent</span>
      </div>
    </footer>
  );
}

export default Footer;
