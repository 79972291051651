import { FiX } from "react-icons/fi";

import "./modalDistribuidores.css";
import { TableDistribuidores } from "./table";

export function ModalDistribuidores({ close }) {
  return (
    <div className="modal-distribuidores">
      <div className="content-distribuidores">
        <div className="header-distribuidores">
          <span>Conoce a los distribuidores participantes</span>
          <button className="close" onClick={() => close()}>
            <FiX size={25} color={"#fff"} />
          </button>
        </div>
        <TableDistribuidores />
      </div>
    </div>
  );
}
