import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  background-color: #10233f;

  > h1 {
    color: #fff;
  }
`;

export const Button = styled(Link)`
  height: 42px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  background-color: #42baef;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;
