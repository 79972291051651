import api from "../../utils/api";
import { configJson } from "../firebaseUtils";
import authToken from "./authToken";

export default function setAdditionalInfos(uid, campaignId, recipient, data) {
  return new Promise((resolve, reject) => {
    localStorage.clear("ivoclarToken");
    authToken(uid)
      .then((token) => {
        api
          .post(
            `${configJson.API_HOST_NODE}/Ivoclar/setAdditionalInfos`,
            {
              campaignId,
              recipient,
              data,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((result) => {
            return resolve(result);
          })
          .catch((error) => {
            console.log("ERROR_SET_ADDITIONAL_INFOS: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log('ERROR_TOKEN===>', error);
        reject(error);
      });
  });
}
