import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { checkDevice } from '../../utils/helpers';
import { useTranslation, Trans } from 'react-i18next';

import bannerDesktop from '../../assets/images/ivoclarBannerDesktop.png';
import bannerDesktop_ES from '../../assets/images/ivoclarBannerDesktop_ES.png';
import bannerMobile from '../../assets/images/ivoclarBannerMobile.png';
import bannerMobile_ES from '../../assets/images/ivoclarBannerMobile_ES.png';

import add_product_01 from '../../assets/images/add_product-stap_01.png';
import add_product_01_ES from '../../assets/images/add_product-stap_01_ES.jpg';
import add_product_02 from '../../assets/images/add_product-stap_02.png';
import add_product_02_ES from '../../assets/images/add_product-stap_02_ES.jpg';
import add_product_03 from '../../assets/images/add_product-stap_03.png';
import add_product_03_ES from '../../assets/images/add_product-stap_03_ES.png';
import add_product_04 from '../../assets/images/image-nota.png';
import add_product_04_ES from '../../assets/images/image-nota_ES.png';
import add_product_05 from '../../assets/images/add_product-stap_05.png';
import add_product_05_ES from '../../assets/images/add_product-stap_05_ES.png';
import add_product_06 from '../../assets/images/add_product-stap_06.png';
import add_product_06_ES from '../../assets/images/add_product-stap_06_ES.png';

import request_prize_02 from '../../assets/images/request_prize-stap_02.png';
import request_prize_02_ES from '../../assets/images/request_prize-stap_02_ES.jpg';
import request_prize_03 from '../../assets/images/request_prize-stap_03.png';
import request_prize_03_ES from '../../assets/images/request_prize-stap_03_ES.png';
import request_prize_04 from '../../assets/images/request_prize-stap_04.png';
import request_prize_04_ES from '../../assets/images/request_prize-stap_04_ES.png';
import request_prize_05 from '../../assets/images/request_prize-stap_05.png';
import request_prize_05_ES from '../../assets/images/request_prize-stap_05_ES.png';

import './tutorial-style.css';

export default function Tutorial() {
  const { t } = useTranslation();
  const languageForImg = localStorage.getItem('i18nextLng').replace('-', '_');

  const [isMobile, setIsMobile] = useState(false);
  const [isShowTutorialOne, setIsShowTutorialOne] = useState(false);
  const [isShowTutorialTwo, setIsShowTutorialTwo] = useState(false);
  const [imgBannerDesktop, setImgBannerDesktop] = useState('');
  const [imgBannerMobile, setImgBannerMobile] = useState('');

  const [imgProductOne, setImgProductOne] = useState('');
  const [imgProductTwo, setImgProductTwo] = useState('');
  const [imgProductThree, setImgProductThree] = useState('');
  const [imgProductFour, setImgProductFour] = useState('');
  const [imgProductFive, setImgProductFive] = useState('');
  const [imgProductSix, setImgProductSix] = useState('');

  const [imgRequestTwo, setImgRequestTwo] = useState('');
  const [imgRequestThree, setImgRequestThree] = useState('');
  const [imgRequestFour, setImgRequestFour] = useState('');
  const [imgRequestFive, setImgRequestFive] = useState('');

  useEffect(() => {
    // FUNÇÕES PARA MUDANÇA DE IMAGENS CONFORME IDIOMA

    function toggleBannerDesktop() {
      if (languageForImg === 'pt_BR') {
        return bannerDesktop;
      } else {
        return bannerDesktop_ES;
      }
    }

    function toggleBannerMobile() {
      if (languageForImg === 'pt_BR') {
        return bannerMobile;
      } else {
        return bannerMobile_ES;
      }
    }

    function toggleStap1() {
      if (languageForImg === 'pt_BR') {
        return add_product_01;
      } else {
        return add_product_01_ES;
      }
    }

    function toggleStap2() {
      if (languageForImg === 'pt_BR') {
        return add_product_02;
      } else {
        return add_product_02_ES;
      }
    }

    function toggleStap3() {
      if (languageForImg === 'pt_BR') {
        return add_product_03;
      } else {
        return add_product_03_ES;
      }
    }

    function toggleStap4() {
      if (languageForImg === 'pt_BR') {
        return add_product_04;
      } else {
        return add_product_04_ES;
      }
    }

    function toggleStap5() {
      if (languageForImg === 'pt_BR') {
        return add_product_05;
      } else {
        return add_product_05_ES;
      }
    }

    function toggleStap6() {
      if (languageForImg === 'pt_BR') {
        return add_product_06;
      } else {
        return add_product_06_ES;
      }
    }

    function toggleStapRequest2() {
      if (languageForImg === 'pt_BR') {
        return request_prize_02;
      } else {
        return request_prize_02_ES;
      }
    }

    function toggleStapRequest3() {
      if (languageForImg === 'pt_BR') {
        return request_prize_03;
      } else {
        return request_prize_03_ES;
      }
    }

    function toggleStapRequest4() {
      if (languageForImg === 'pt_BR') {
        return request_prize_04;
      } else {
        return request_prize_04_ES;
      }
    }

    function toggleStapRequest5() {
      if (languageForImg === 'pt_BR') {
        return request_prize_05;
      } else {
        return request_prize_05_ES;
      }
    }
    // -----------------------------------------------------
    setImgBannerDesktop(toggleBannerDesktop());
    setImgBannerMobile(toggleBannerMobile());

    setImgProductOne(toggleStap1());
    setImgProductTwo(toggleStap2());
    setImgProductThree(toggleStap3());
    setImgProductFour(toggleStap4());
    setImgProductFive(toggleStap5());
    setImgProductSix(toggleStap6());

    setImgRequestTwo(toggleStapRequest2());
    setImgRequestThree(toggleStapRequest3());
    setImgRequestFour(toggleStapRequest4());
    setImgRequestFive(toggleStapRequest5());
  }, [languageForImg]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobile(checkDevice());
    setIsShowTutorialOne(false);
    setIsShowTutorialTwo(false);
  }, []);

  return (
    <>
      <Header isTutorial={true} />
      <div className='container-tutorial'>
        <div className='banner-tutorial-content'>
          <img
            src={isMobile ? imgBannerMobile : imgBannerDesktop}
            alt='Banner'
          />
        </div>

        <h1>{t('tutorial.common.title')}</h1>
        {/* LINHA DE DIVISÃO */}

        <div className='content-tutotial-add'>
          {isShowTutorialOne && (
            <>
              <div className='item-tutoria '>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>1</strong>
                  </div>
                  <span>{t('tutorial.common.logged_on_area')}</span>
                </div>
                <img src={imgProductOne} alt='Passo 1' />
              </div>

              <div className='item-tutoria'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>2</strong>
                  </div>
                  <span>
                    <Trans i18nKey='tutorial.common.register_award' />
                  </span>
                </div>
                <img src={imgProductTwo} alt='Passo 2' />
              </div>

              <div className='item-tutoria'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>3</strong>
                  </div>
                  <span>
                    <Trans i18nKey='tutorial.common.add_number' />
                  </span>
                </div>
                <img src={imgProductThree} alt='Passo 3' />
              </div>

              <div className='item-tutoria'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>3.1</strong>
                  </div>
                  <span>
                    <Trans i18nKey='tutorial.common.find_NF' />
                  </span>
                </div>
                <img src={imgProductFour} alt='Passo 4' />
              </div>

              <div className='item-tutoria'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>3.2</strong>
                  </div>
                  <span>
                    <Trans i18nKey='tutorial.common.add_more_coupons' />
                  </span>
                </div>
                <img src={imgProductFive} alt='Passo 5' />
              </div>

              <div className='item-tutoria'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>4</strong>
                  </div>
                  <span>
                    <Trans i18nKey='tutorial.common.select_options' />
                  </span>
                </div>
                <img
                  style={{ marginLeft: '20px' }}
                  src={imgProductSix}
                  alt='Passo 6'
                />
              </div>
            </>
          )}
          <span
            className='button-open-tutorial'
            onClick={() => setIsShowTutorialOne(!isShowTutorialOne)}>
            {isShowTutorialOne
              ? t('tutorial.common.close_tutorial')
              : t('tutorial.common.open_tutorial')}
          </span>
        </div>

        <h1>
          <Trans i18nKey='tutorial.common.claim_your_prize' />
        </h1>

        <div className='content-tutotial-request'>
          {isShowTutorialTwo && (
            <>
              <div className='item-tutorial-request'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>1</strong>
                  </div>
                  <div className='textCircle'>
                    <span>{t('tutorial.common.logged_on_area')}</span>
                  </div>
                </div>

                <img src={imgProductOne} alt='passo 01' />
              </div>

              <div className='item-tutorial-request'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>2</strong>
                  </div>
                  <div className='textCircle'>
                    <span>
                      <Trans i18nKey='tutorial.common.request_award' />
                    </span>
                  </div>
                </div>
                <img src={imgRequestTwo} alt='passo 02' />
              </div>

              <div className='item-tutorial-request'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>3</strong>
                  </div>
                  <div className='textCircle'>
                    <span>
                      <Trans i18nKey='tutorial.common.preferred_dental' />
                    </span>
                  </div>
                </div>
                <img src={imgRequestThree} alt='passo 03' />
              </div>

              <div className='item-tutorial-request'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>4</strong>
                  </div>
                  <div className='textCircle'>
                    <span>{t('tutorial.common.confirm_informations')}</span>
                  </div>
                </div>
                <img src={imgRequestFour} alt='passo 04' />
              </div>

              <div className='item-tutorial-request'>
                <div className='item-circle'>
                  <div className='border-gradient'>
                    <strong className='areaCircle'>5</strong>
                  </div>
                  <div className='textCircle'>
                    <span>
                      <Trans i18nKey='tutorial.common.track_your_history' />
                    </span>
                  </div>
                </div>
                <img src={imgRequestFive} alt='passo 05' />
              </div>
            </>
          )}
          <span
            className='button-open-tutorial'
            onClick={() => setIsShowTutorialTwo(!isShowTutorialTwo)}>
            {' '}
            {isShowTutorialTwo
              ? t('tutorial.common.close_tutorial')
              : t('tutorial.common.open_tutorial')}
          </span>
        </div>

        <div className='section-stapTwo'>
          <h4>{t('tutorial.common.doubts')}</h4>
        </div>

        <Footer />
      </div>
    </>
  );
}
