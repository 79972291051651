import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(countries, cities, representatives, contacts) {
  return { countries, cities, representatives, contacts };
}
const rows = [
  createData(
    "Guatemala, Colombia, Ecuador, Paraguay, Bolivia",
    null,
    "Mariana Zarate",
    "http://bit.ly/3o79T3O"
  ),
  createData(
    "Chile, Costa Rica, República Dominicana, Perú, Argentina, Uruguay.",
    null,
    "Graciela Garcia",
    "http://bit.ly/3MxrUCv"
  ),
  createData(
    "México",
    "Estado de México, Hidalgo.",
    "Sandra Lara",
    "http://bit.ly/3Urp5F0"
  ),
  createData(
    // "México",
    null,
    "Ciudad de México, Morelos.",
    "Alma Vargas",
    "http://bit.ly/43Bk2WK"
  ),
  createData(
    // "México",
    null,
    "Tabasco, Oaxaca, Veracruz, Puebla, Guerrero.",
    "Fernanda Romero",
    "http://bit.ly/41iRsXZ"
  ),
  createData(
    // "México",
    null,
    "Quintana Roo, Yucatán, Campeche, Chiapas, Tlaxcala.",
    "Angelica Juarez",
    "http://bit.ly/3KRh0WZ"
  ),
  createData(
    // "México",
    null,
    "Baja California, Sonora, Sinaloa, Nayarit.",
    "Alejandro Yañez",
    "http://bit.ly/3Kx4CtS"
  ),
  createData(
    // "México",
    null,
    "Jalisco, Colima, Aguascalientes, Michoacán.",
    "Jaime Moreno",
    "http://bit.ly/3mooCqz"
  ),
  createData(
    // "México",
    null,
    "Nuevo León, San Luis Potosí, Guanajuato, Tamaulipas.",
    "Roberto Almanza",
    "http://bit.ly/40gwGrn"
  ),
  createData(
    // "México",
    null,
    "Chihuahua, Coahuila, Durango, Zacatecas.",
    "Prisila Montiel",
    "http://bit.ly/3mz4MbY"
  ),
];

export function TableContactos() {
  let whatScreen = window.screen.width;

  if (whatScreen >= 450) {
    return (
      <TableContainer
        component={Paper}
        style={{
          marginTop: "5rem",
          overflowY: "scroll",
          height: "500px",
        }}
      >
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Países</b>
              </TableCell>
              <TableCell align="right">
                <b>Ciudades</b>
              </TableCell>
              <TableCell align="right">
                <b>Persona de Contacto</b>
              </TableCell>
              <TableCell align="right">
                <b>Contacto</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.representatives}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.countries}
                </TableCell>
                <TableCell align="right">{row.cities}</TableCell>
                <TableCell align="right">{row.representatives}</TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      fontWeight: 600,
                      textDecoration: "none",
                      color: "#25D366",
                    }}
                    href={row.contacts}
                  >
                    Whatsapp
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer
        component={Paper}
        style={{
          marginTop: "5rem",
          overflowY: "scroll",
          height: "500px",
          padding: "1rem",
          lineHeight: "1.5rem",
        }}
      >
        <b>Países: </b>
        {rows[0].countries}
        <br />
        <b>Persona de Contacto: </b>
        {rows[0].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[0].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Países: </b>
        {rows[1].countries}
        <br />
        <b>Persona de Contacto: </b>
        {rows[1].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[1].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Países: </b>
        {rows[2].countries}
        <br />
        <b>Ciudades: </b>
        {rows[2].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[2].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[2].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[3].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[3].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[3].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[4].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[4].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[4].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[5].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[5].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[5].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[6].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[6].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[6].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[7].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[7].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[7].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[8].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[8].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[8].contacts}
        >
          Whatsapp
        </a>

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[9].cities}
        <br />
        <b>Persona de Contacto: </b>
        {rows[9].representatives}
        <br />
        <b>Contacto: </b>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 600, textDecoration: "none", color: "#25D366" }}
          href={rows[9].contacts}
        >
          Whatsapp
        </a>
      </TableContainer>
    );
  }
}
