import React from "react";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import { configJson } from "../../services/firebaseUtils";
import { encrypt } from "../../utils/helpers";

import "./modalInformation.css";

const CRYPTO_KEY = configJson.CRYPTO_KEY;

function ModalInformation({
  close,
  setCouponNumbers,
  setImagePath,
  setNewImage,
  setTaxNumber,
  cellPhone,
  couponNumbersValid,
  couponNumbersInvalid,
}) {
  const { t } = useTranslation();

  function linkWebApp() {
    const storageUser = localStorage.getItem("IvoclarUser");
    let LINK_WEB_APP_LOGADO = "";

    if (storageUser) {
      // LINK PARA DIRECIONAR LOGADO WEB-APP
      LINK_WEB_APP_LOGADO = `${
        configJson.LINK_SITE_TRIIBO
      }/entrance/?userId=${encodeURIComponent(
        encrypt(cellPhone, CRYPTO_KEY)
      )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;
    } else {
      LINK_WEB_APP_LOGADO = `${configJson.LINK_SITE_TRIIBO}`;
    }
    window.location.href = LINK_WEB_APP_LOGADO;
    close();
  }

  return (
    <div className="modal-information">
      <div
        className={`container-information ${
          couponNumbersInvalid.length > 0 && "cuponsInvalid"
        }`}
      >
        <div className="header-modal-information">
          <span>
            {couponNumbersInvalid.length > 0
              ? t("modalInformation.alerts.product_register")
              : t("modalInformation.alerts.product_register_success")}
          </span>
          <button
            className="close"
            onClick={() => {
              close();
              setCouponNumbers([""]);
              setTaxNumber("");
              setImagePath(null);
              setNewImage(null);
            }}
          >
            <FiX size={25} color={"#fff"} />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
          }}
        >
          <div className="text-information-content">
            <span>
              {couponNumbersInvalid.length > 0 ? (
                <>
                  {t(
                    "modalInformation.alerts.congratulations_cupons_processed_pt1"
                  )}{" "}
                  {couponNumbersValid.map((cupon) => (
                    <span>[ {cupon} ]</span>
                  ))}{" "}
                  {t(
                    "modalInformation.alerts.congratulations_cupons_processed_pt2"
                  )}
                </>
              ) : (
                t(
                  "modalInformation.alerts.congratulations_all_cupons_processed"
                )
              )}
            </span>
            {couponNumbersInvalid.length > 0 && (
              <>
                <h5>
                  {t(
                    "modalInformation.alerts.warn_congratulations_cupons_processed_title"
                  )}
                </h5>
                <span>
                  {t(
                    "modalInformation.alerts.warn_congratulations_cupons_processed_pt1"
                  )}{" "}
                  {couponNumbersInvalid.map((cupon) => (
                    <span>[ {cupon} ]</span>
                  ))}{" "}
                  {t(
                    "modalInformation.alerts.warn_congratulations_cupons_processed_pt2"
                  )}
                </span>
              </>
            )}
          </div>
          <button
            className="button-information"
            onClick={linkWebApp}
            type="button"
          >
            {t("modalInformation.buttons.conclude")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalInformation;
