import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";

// import configHml from "../config/config-hml.json";
import configPrd from "../config/config-prd.json";

export const configJson =
  process.env.NODE_ENV === "development" ? configPrd : configPrd;

const config = {
  apiKey: configJson.FIREBASE_APIKEY,
  authDomain: configJson.FIREBASE_AUTHDOMAIN,
  databaseURL: configJson.FIREBASE_DATABASEURL,
  projectId: configJson.FIREBASE_PROJECTID,
  storageBucket: configJson.FIREBASE_STORAGEBUCKET,
};

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseDatabase = firebase.database();
export const firebaseAuth = firebase.auth();
export const firebaseStorage = firebase.storage();
