import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import phoneMaskArray from "../../phoneMaskArray.json";
import fixedPhoneMaskArray from "../../fixedPhoneMaskArray.json";
import InputMask from "react-input-mask";

import { SignUpFormContext } from "../../contexts/signUpForm";
import { UserContext } from "../../contexts/user";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { configJson } from "../../services/firebaseUtils";

import "./customersInfo.css";
import { useTranslation } from "react-i18next";

const body = document.querySelector("body");

const PartnerIdIvoclar = configJson.PARTNER_ID_IVOCLAR;
const CAMPAIGN_ID = configJson.CAMPAIGN_ID;

const LINK_PDF =
  "https://firebasestorage.googleapis.com/v0/b/project-4616920480246266429.appspot.com/o/seuClube%2FLegales%20Plataforma%20Triibos%20VI.pdf?alt=media";
const LINK_PRIVACY_POLICY =
  "https://www.ivoclarvivadent.com/en_US/Company/About-Us";

function CustomersInfo() {
  // PEGA OS DADOS DO CONTEXT
  const { userRole, entityType } = useContext(SignUpFormContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [cep, setCep] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [dataFormSignUp, setDataFromSignUp] = useState("");
  const [messageErrorCpf, setMessageErrorCpf] = useState("");
  const [messageErrorApi, setMessageErrorApi] = useState("");
  const [messageErrorCep, setMessageErrorCep] = useState("");
  const [messageErrorTerms, setMessageErrorTerms] = useState("");

  const [hasEmptyInput, setHasEmptyInput] = useState(false);

  const [isNotification, setIsNotification] = useState(true);
  const [isRegulation, setIsRegulation] = useState(true);
  const [isTerms, setIsTerms] = useState(true);

  // MASCARA DE CELULARES PARA AMERICA LATINA
  const [currentCellPhoneCountry, setCurrentCellPhoneCountry] = useState("");
  const [currentCellPhoneDDD, setCurrentCellPhoneDDD] = useState("");
  const [cellPhoneInputField, setCellPhoneInputField] = useState("");
  const [cellPhoneInputMask, setCellPhoneInputMask] = useState("");
  const [cellPhonefullMaskSelected, setCellPhoneFullMaskSelected] =
    useState("+55(00)00000-0000");

  // MASCARA DE TELEFONE FIXO PARA AMERICA LATINA
  const [currentFixedPhoneCountry, setCurrentFixedPhoneCountry] = useState("");
  const [currentFixedPhoneDDD, setCurrentFixedPhoneDDD] = useState("");
  const [fixedPhoneInputField, setFixedPhoneInputField] = useState("");
  const [fixedPhoneInputMask, setFixedPhoneInputMask] = useState("");
  const [fixedPhonefullMaskSelected, setFixedPhoneFullMaskSelected] =
    useState("+55(00)0000-0000");

  // DADOS DO FORMULÁRIO ANTERIOR
  const location = useLocation();
  const history = useHistory();

  // PEGA DADOS DO LOCAL_STORAGE
  useEffect(() => {
    function loadingStorage() {
      const storageUser = localStorage.getItem("UserRole");

      if (storageUser) {
        setDataFromSignUp(JSON.parse(storageUser));
      }
    }

    loadingStorage();
  }, []);

  useEffect(() => {
    if (
      name !== "" &&
      lastName !== "" &&
      cellPhone.replace(/\D/g, "").length ===
        cellPhonefullMaskSelected.replace(/\D/g, "").length &&
      email !== "" &&
      country !== "" &&
      state !== "" &&
      city !== ""
    ) {
      setHasEmptyInput(true);
    } else {
      setHasEmptyInput(false);
    }
  }, [
    name,
    lastName,
    cellPhone,
    cellPhonefullMaskSelected,
    phoneNumber,
    fixedPhonefullMaskSelected,
    email,
    country,
    state,
    city,
    street,
  ]);

  const handleCellPhoneCountryChange = (e) => {
    const valuesArr = e.target.value.split(","); //transforma a string em um array a partir da vírgula
    setCellPhoneInputField("");
    setCurrentCellPhoneDDD(valuesArr[0]);
    setCellPhoneInputMask(valuesArr[1]);
    setCellPhoneFullMaskSelected(valuesArr[2]);
    setCurrentCellPhoneCountry(e.target.value);
  };

  const handleCellPhoneInputChange = (e) => {
    setCellPhoneInputField(e.target.value);
  };

  useEffect(() => {
    setCellPhone(
      "+" +
        currentCellPhoneDDD.replace(/\D/g, "") +
        cellPhoneInputField.replace(/\D/g, "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellPhoneInputField]);

  useEffect(() => {
    console.log(cellPhone);
  }, [cellPhone]);

  const handleFixedPhoneCountryChange = (e) => {
    const valuesArr = e.target.value.split(","); //transforma a string em um array a partir da vírgula
    setFixedPhoneInputField("");
    setCurrentFixedPhoneDDD(valuesArr[0]);
    setFixedPhoneInputMask(valuesArr[1]);
    setFixedPhoneFullMaskSelected(valuesArr[2]);
    setCurrentFixedPhoneCountry(e.target.value);
  };

  const handleFixedPhoneInputChange = (e) => {
    setFixedPhoneInputField(e.target.value);
  };

  useEffect(() => {
    setPhoneNumber(
      "+" +
        currentFixedPhoneDDD.replace(/\D/g, "") +
        fixedPhoneInputField.replace(/\D/g, "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedPhoneInputField]);

  async function handleSubmit(event) {
    event.preventDefault();

    // VERIFICA OS TERMOS
    if (!isTerms) {
      setMessageErrorTerms(t("customersInfo.alerts.accept_privacy_policy"));
      return;
    }

    if (!isRegulation) {
      setMessageErrorTerms(
        t("customersInfo.alerts.accept_promotion_regulation")
      );
      return;
    }

    const oldValuePartnerList = [];
    // VERIFICA SE O USUÁRIO TEM OUTROS PARTNER_LIST
    if (user?.partnerList) {
      const partnerlist = user?.partnerList;

      partnerlist.forEach((partner) => {
        if (partner.partnerId !== PartnerIdIvoclar) {
          oldValuePartnerList.push(partner);
        }
      });
    }

    const dataPartner = {
      partnerList: {
        name: name.trim(),
        lastName: lastName.trim(),
        addressList: [
          {
            state: state,
            city: city,
            country: country,
            number: "",
            extra: "",
            zipCode: cep.replace(/\D/g, ""),
            street: street,
            type: "comercial",
          },
        ],
        contactList: [
          {
            type: "email",
            value: email,
          },
          {
            type: "cellPhone",
            value: cellPhone.replace(/\D/g, ""),
          },
        ],
        entityType: entityType ? entityType : dataFormSignUp?.selectedItem,
        campaignId: CAMPAIGN_ID,
        optInList: [
          {
            type: t("customersInfo.types.terms_of_use_regulation"),
            optInId: "-TermoDeUsoIvoclar-01",
            version: 1.0,
            dateAcceptance: Date.now(),
            accept: isRegulation,
          },
        ],
      },
    };

    const dataFormOperator = location.state;

    // DADOS DO PARTNER, CASO O USUÁRIO TENHA OUTROS PARTNER MANTEM OS ANTIGOS E ADICIONA O NOVO.
    const dataOperator = {
      partnerList: [
        ...oldValuePartnerList.map((partners) => partners),
        {
          contactList: [
            {
              type: "email",
              value: dataFormOperator.email,
            },
          ],
          name: dataFormOperator.aliasName,
          lastName: dataFormOperator.lastName,
          userRole: userRole ? userRole : dataFormSignUp?.selectedRole,
          partnerName: "IvoClar",
          partnerId: PartnerIdIvoclar,
          optInList: [
            {
              type: t("customersInfo.types.terms_of_use_received_email"),
              optInId: "-TermoDeUsoIvoclar-02",
              version: 1.0,
              dateAcceptance: Date.now(),
              accept: isNotification,
            },
            {
              type: t("customersInfo.types.terms_of_use_privacy_policy"),
              optInId: "-TermoDeUsoIvoclar-03",
              version: 1.0,
              dateAcceptance: Date.now(),
              accept: isTerms,
            },
          ],
        },
      ],
    };

    // ARMAZENA OS DADOS DO FORMULÁRIO DO LOCAL_STORAGE, PARA PREENCHER OS CAMPOS CASO O USUÁRIO VOLTE NA TELA
    localStorage.setItem(
      "ivoclar_ProficionalData",
      JSON.stringify({ dataPartnerList: dataPartner.partnerList })
    );

    const uId = user?.uId;

    history.push({
      pathname: "/cadastro-adicional",
      state: { partner: dataPartner, operator: dataOperator, uId },
    });
  }

  useEffect(() => {
    body.style.backgroundColor = "#10233f";

    return () => {
      body.style.backgroundColor = "#fff";
    };
  }, []);

  // RESET PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
    body.style.overflow = "";
    setMessageErrorApi("");
    setHasEmptyInput(false);
    setMessageErrorCpf("");
    setMessageErrorTerms("");
  }, []);

  useEffect(() => {
    if (messageErrorCpf !== "") {
      window.scrollTo(0, 0);
    }
  }, [messageErrorCpf]);

  // BLOQUEIO DE ACESSO DIRETO NA PÁGINA
  useEffect(() => {
    const userLocalStorage = JSON.parse(localStorage.getItem("IvoclarUser"));

    if (userLocalStorage === null) {
      history.push("/");
    }
  }, [history]);

  return (
    <>
      <Header isShowLinks={false} />
      <div className="container-customersInfo">
        <h1>{t("customersInfo.common.title")}</h1>

        <p>{t("customersInfo.common.description_inform")}</p>

        <form
          onSubmit={handleSubmit}
          onChange={() => {
            setMessageErrorTerms("");
            setMessageErrorCep("");
            setMessageErrorApi("");
          }}
          className="main-content-customers"
        >
          <div className="area-input-customers">
            <label>{t("customersInfo.labels.name")}</label>
            <input
              type="text"
              name="name"
              placeholder={t("customersInfo.placeholders.name")}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <label>{t("customersInfo.labels.lastName")}</label>
            <input
              type="text"
              name="lastName"
              placeholder={t("customersInfo.placeholders.lastName")}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <label>{t("customersInfo.labels.cellphone")}</label>
            <div className="inputs-container">
              <select
                value={currentCellPhoneCountry}
                onChange={handleCellPhoneCountryChange}
              >
                <option disabled value="">
                  País
                </option>
                {phoneMaskArray.map((country, index) => (
                  <option
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
              <InputMask
                disabled={currentCellPhoneDDD === ""}
                mask={cellPhoneInputMask}
                value={cellPhoneInputField}
                placeholder={cellPhoneInputMask}
                onChange={handleCellPhoneInputChange}
              />
            </div>
            <label>{t("customersInfo.labels.phone_number")}</label>
            <div className="inputs-container">
              <select
                value={currentFixedPhoneCountry}
                onChange={handleFixedPhoneCountryChange}
              >
                <option disabled value="">
                  País
                </option>
                {fixedPhoneMaskArray.map((country, index) => (
                  <option
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
              <InputMask
                disabled={currentFixedPhoneDDD === ""}
                mask={fixedPhoneInputMask}
                value={fixedPhoneInputField}
                placeholder={fixedPhoneInputMask}
                onChange={handleFixedPhoneInputChange}
              />
            </div>

            <label>{t("customersInfo.labels.email")}</label>
            <input
              type="text"
              name="email"
              placeholder={t("customersInfo.placeholders.email")}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {messageErrorCep && (
              <span style={{ color: "red", fontSize: "13px" }}>
                {messageErrorCep}
              </span>
            )}
            <label>{t("customersInfo.labels.postal_code")}</label>
            <input
              type="text"
              name="cep"
              maxLength="8"
              // onBlur={onBlurCep}
              placeholder={t("customersInfo.placeholders.postal_code")}
              value={cep}
              onChange={(e) => {
                setCep(e.target.value);
              }}
            />
          </div>

          <div className="area-input-customers">
            <label>{t("customersInfo.labels.country")}</label>
            <input
              type="text"
              name="country"
              placeholder={t("customersInfo.placeholders.country")}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />

            <label>{t("customersInfo.labels.district")}</label>
            <input
              type="Estado"
              name="country"
              placeholder={t("customersInfo.placeholders.district")}
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />

            <label>{t("customersInfo.labels.city")}</label>
            <input
              type="text"
              name="city"
              placeholder={t("customersInfo.placeholders.city")}
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />

            <label>{t("customersInfo.labels.street")}</label>
            <input
              type="text"
              name="street"
              placeholder={t("customersInfo.placeholders.street")}
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
            />
            {/* <label>{t("customersInfo.labels.number")}</label>
            <input
              type="text"
              name="number"
              placeholder={t("customersInfo.placeholders.number")}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />

            <label>{t("customersInfo.labels.complement")}</label>
            <input
              type="text"
              name="aditional"
              placeholder={t("customersInfo.placeholders.complement")}
              value={aditionalInformation}
              onChange={(e) => setAditionalInformation(e.target.value)}
            /> */}
          </div>
        </form>

        <div className="area-checkboxs">
          <div style={{ marginBottom: "20px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                {t("customersInfo.terms_of_usage.promotion_regulamention")}
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => {
                  setIsRegulation((oldValue) => !oldValue);
                  setMessageErrorTerms("");
                }}
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                {t("customersInfo.terms_of_usage.privacy_policy_ivoclar")}
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => {
                  setIsTerms((oldValue) => !oldValue);
                  setMessageErrorTerms("");
                }}
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div style={{ marginBottom: "50px" }}>
            <label className="container-checkbox">
              <span className="text-checkbox">
                {t("customersInfo.terms_of_usage.send_notifications")}
              </span>
              <input
                defaultChecked
                type="checkbox"
                onClick={() => setIsNotification((oldValue) => !oldValue)}
                className="checked"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <span style={{ color: "#FFFF", fontSize: "0.7rem" }}>
            {t("customersInfo.terms_of_usage.look")}{" "}
            <a
              style={{ color: "#fff" }}
              title="Políticas de Privacidade"
              target="_blank"
              rel="noreferrer"
              href={LINK_PDF}
            >
              {t("customersInfo.terms_of_usage.look_promotion_regulation")}
            </a>
          </span>

          <span style={{ color: "#FFFF", fontSize: "0.7rem" }}>
            {t("customersInfo.terms_of_usage.look")}{" "}
            <a
              style={{ color: "#fff" }}
              title="Políticas de Privacidade"
              target="_blank"
              rel="noreferrer"
              href={LINK_PRIVACY_POLICY}
            >
              {t("customersInfo.terms_of_usage.look_policy_privacy")}
            </a>
          </span>
        </div>

        {messageErrorTerms && (
          <span
            style={{
              color: "red",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            {messageErrorTerms}
          </span>
        )}

        {messageErrorApi && (
          <span
            style={{
              color: "red",
              fontSize: "13px",
              marginBottom: "5px",
            }}
          >
            {messageErrorApi}
          </span>
        )}

        <button
          disabled={!hasEmptyInput}
          className="button-customers"
          type="button"
          style={{
            backgroundColor: `${!hasEmptyInput ? "#b6e1f3" : "#42baef"}`,
            cursor: `${!hasEmptyInput ? "no-drop" : "pointer"}`,
          }}
          onClick={handleSubmit}
        >
          {t("customersInfo.buttons.next")}
        </button>
      </div>
      <Footer />
    </>
  );
}

export default CustomersInfo;
