/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";
import "./header.css";

import { useTranslation } from "react-i18next";
// import { LanguageSwitcher } from "../LanguageSwitcher";

const LINK_IVOCLAR = "https://www.ivoclar.com/es_latam";

const LINK_CLUBE = "https://ivoclar-latam.seuclube.org/";

// PDF DE REGULAMENTO DA PROMOÇÃO FICA TRIIBO-PRD/FIREBASE/STORAGE/IVOCLAR
const LINK_PDF =
  "https://firebasestorage.googleapis.com/v0/b/project-4616920480246266429.appspot.com/o/seuClube%2FLegales%20Plataforma%20Triibos%20VI.pdf?alt=media";

function Header({ setShowModalLogin, isShowLinks, setNextPage }) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const hasActiveMenu = isActive ? "nav-header active" : "nav-header";

  const history = useHistory();

  function toggleMenu() {
    setIsActive((oldValue) => !oldValue);
  }

  function isLogged() {
    const logged = localStorage.getItem("isLogged");
    console.log(logged);
    if (logged) {
      window.location.href = LINK_CLUBE;
    } else {
      setShowModalLogin(true);
      setIsActive((oldValue) => !oldValue);
      setNextPage("triibo-web");
    }
  }

  return (
    <>
      <header className="container-header">
        <a
          style={{ display: "flex" }}
          title="site-ivoclar"
          target="_blank"
          rel="noreferrer"
          href={LINK_IVOCLAR}
        >
          <img src={logo} alt="Logo Ivoclar" className="logo-header" />
        </a>

        <nav className={hasActiveMenu}>
          <button onClick={toggleMenu} id="btn-mobile">
            <span id="hamburger"></span>
          </button>
          <ul className="menu-header">
            <li>
              <a
                onClick={() => {
                  history.push("/");
                  setIsActive((oldValue) => !oldValue);
                }}
                className="header-button"
              >
                {t("header.home")}
              </a>
            </li>
            {isShowLinks && (
              <li>
                <a
                  onClick={() => setIsActive((oldValue) => !oldValue)}
                  title="promoção"
                  href="#promocao"
                >
                  {t("header.about_the_promotion")}
                </a>
              </li>
            )}

            <li>
              <a
                title="carreira"
                target="_blank"
                rel="noreferrer"
                href={LINK_PDF}
              >
                {t("header.regulation")}
              </a>
            </li>

            {isShowLinks && (
              <li>
                <a
                  onClick={() => setIsActive((oldValue) => !oldValue)}
                  title="App triibo"
                  href="#app"
                >
                  {t("header.download_the_app")}
                </a>
              </li>
            )}
            {isShowLinks && (
              <li>
                <a href="#login" onClick={isLogged} className="header-button">
                  {t("header.login")}
                </a>
              </li>
            )}
            {/* {isShowLinks && (
              <li>
                <LanguageSwitcher />
              </li>
            )} */}
            {isShowLinks && (
              <li>
                <a
                  href="#login"
                  onClick={() => {
                    setShowModalLogin(true);
                    setIsActive((oldValue) => !oldValue);
                    setNextPage("/cadastrar");
                  }}
                  className="header-button-register"
                >
                  {t("header.register")}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <div className="margin-botton-header" />
    </>
  );
}

export default Header;
