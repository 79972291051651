import { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { configJson } from "../services/firebaseUtils";
import { getUserInfo_v1 } from "../services/Apis/getUserInfo_v1";
import { authCode_v1 } from "../services/Apis/authCode_v1";
import { validateSMS_v1 } from "../services/Apis/validateSMS_v1";
import { setUserInfo_v1 } from "../services/Apis/setUserInfo_v1";
import { loginUser } from "../services/AuthenticationManager";
import login from "../services/Apis/login";
import { encrypt } from "../utils/helpers";
import getCampaign from "../services/Apis/getCampaign";
import { useTranslation } from "react-i18next";

const PARTNER_ID_IVOCLAR = configJson.PARTNER_ID_IVOCLAR;
const ORG_ID_IVOCLAR = configJson.ORG_ID_IVOCLAR;
const CAMPAIGN_ID = configJson.CAMPAIGN_ID;
const CRYPTO_KEY = configJson.CRYPTO_KEY;

export const UserContext = createContext({});

function ProviderUser({ children }) {
  const [user, setUser] = useState(null);
  const [isRegisteredPhone, setIsRegisteredPhone] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [cellPhone, setCellPhoneUser] = useState("");
  const [operatorCpf, setOperatorCpf] = useState("");
  const [codeSms, setCodeSms] = useState("");
  const [messageErroCellPhone, setMessageErroCellPhone] = useState(
    "Erro tente novamente mais tarde "
  );
  const [messageErroCode, setMessageErroCode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [isSendPhone, setIsSendPhone] = useState(false);
  const [hasPartnerList, setHasPartnerList] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(true);
  const [hasRegistration, setHasRegistration] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [message, setMessage] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isParticipate, setIsPartipate] = useState(false);
  const [sendCodeType, setSendCodeType] = useState({
    sms: false,
    email: false,
    whatsapp: false,
  });

  const history = useHistory();
  const { t } = useTranslation();

  // LINK PARA DIRECIONAR LOGADO WEB-APP
  // triibo.com.br;
  const LINK_WEB_APP_LOGADO = `${
    configJson.LINK_SITE_TRIIBO
  }/entrance/?userId=${encodeURIComponent(
    encrypt(cellPhone, CRYPTO_KEY)
  )}&destiny=${configJson.LINK_ORG_IVOCLAR}`;

  useEffect(() => {
    setLoading(false);
    setIsSendPhone(false);
    setHasRegistration(false);
    setMessageErroCellPhone("");
    setMessageErroCode("");
    setMessage("");
    setIsRegisteredUser(true);
    setIsRegisteredPhone(false);
  }, []);

  useEffect(() => {
    function loadingStorage() {
      const storageUser = localStorage.getItem("IvoclarUser");

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setAuthenticated(true);
      }
    }

    loadingStorage();
  }, []);

  function storageUser(data) {
    localStorage.setItem(
      "IvoclarUser",
      JSON.stringify({ ...data, cpf: operatorCpf })
    );
    setAuthenticated(true);
  }

  async function createNewUser() {
    login(cellPhone)
      .then(async (result) => {
        const { uId, passPhrase, triiboId, orgs } = result.data.user;
        storageUser(result.data.user);
        setUser(result.data.user);
        let emailLogin = triiboId.replace(/[,]/gi, ".");
        //LOGA USUARIO NO FIREBASE
        await loginUser(emailLogin, passPhrase);
        nextPage === "/cadastrar"
          ? history.push(nextPage)
          : addUserData(uId, orgs);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("CONTEXT_USER_ERROR: ", error);
        setLoading(false);
        setMessage();
        setMessageErroCode(t("user.alerts.went_wrong"));
      });
  }

  //ADICIONA DADOS NA TABELA USERS-V3
  async function addUserData(uId, orgs) {
    const uIdUser = uId === undefined ? user?.uId : uId;
    const orgsUser = orgs === undefined ? user?.orgs : orgs;

    const plantnerName = "ivoclar";
    const newKeyWordsSetLimit = [plantnerName];
    const newKeyWordsSetCustom = [plantnerName];
    const newKeyWordsSetConsumption = [plantnerName];
    const newOrgs = [ORG_ID_IVOCLAR];

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetLimit) &&
      user?.keyWordsSetLimit.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetLimit.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.keyWordsSetCustom) &&
      user?.keyWordsSetCustom.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetCustom.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM KEYWORDS
    Array.isArray(user?.KeyWordsSetConsumption) &&
      user?.KeyWordsSetConsumption.forEach((keyWord) => {
        keyWord !== plantnerName && newKeyWordsSetConsumption.push(keyWord);
      });

    // VERIFICA SE O USUÁRIO TEM ORGS
    Array.isArray(orgsUser) &&
      orgsUser.forEach((org) => {
        org !== ORG_ID_IVOCLAR && newOrgs.push(org);
      });

    const currentDate = new Date().getTime();
    //INFORMAÇÕES PARA CHAMAR A API SET_USER_INFO
    const userData = {
      authenticationMethodList: [
        {
          cellPhone: cellPhone,
          dateVerified: currentDate,
          enabled: true,
          lastlogin: currentDate,
          type: "SMS - Triibo",
          verified: true,
        },
      ],
      contactList: [
        {
          type: "cellPhone",
          value: cellPhone,
        },
        {
          type: "email",
          value: data?.email,
        },
      ],
      documentList: [
        {
          type: "cpf",
          value: data?.cpf,
        },
      ],
      keyWordsSetLimit: [...newKeyWordsSetLimit],
      keyWordsSetCustom: [...newKeyWordsSetCustom],
      keyWordsSetConsumption: [...newKeyWordsSetConsumption],
      orgs: [...newOrgs],
      name: data?.name,
      aliasName: data?.aliasName,
      updateDate: currentDate,
    };

    // API QUE ADICIONA DADOS NO USUÁRIO
    setUserInfo_v1(uIdUser, userData)
      .then((result) => {
        setLoading(false);
        history.push(nextPage);
      })
      .catch((error) => {
        console.log("CONTEXT_USER_ERROR: ", error);
        setLoading(false);
        setMessage(t("user.alerts.went_wrong"));
        setMessageErroCode(t("user.alerts.went_wrong"));
      });
  }

  // VERIFICA O USUÁRIO PELO TELEFONE
  async function checkUserWithCellPhone(nextPage, data) {
    setLoading(true);
    setIsRegisteredPhone(false);
    setData(data);
    setNextPage(nextPage);
    // INFORMAÇÕES PARA CHAMAR A API GET_USER_INFO
    const userInfo = {
      contactList: [
        {
          type: "cellPhone",
          value: cellPhone,
        },
      ],
    };

    getUserInfo_v1(userInfo)
      .then(async (resultUser) => {
        // setLoading(false);
        setUser(resultUser);
        storageUser(resultUser);

        let hasPartnerIdIvoclar = false;

        // VERIFICA SE O USUÁRIO TEM O PARNERLIST IVOCLAR
        if (!!resultUser?.partnerList) {
          const { partnerList } = resultUser;

          partnerList.forEach(({ partnerId }) => {
            if (partnerId === PARTNER_ID_IVOCLAR) hasPartnerIdIvoclar = true;
          });
        }

        //USUÁRIO AINDA NÃO TEM CADASTRO
        if (resultUser === false) {
          setIsRegistered(false);
          if (nextPage === "triibo-web") {
            setIsRegisteredUser(false);
            setLoading(false);
            setMessage(t("messageError.common.phone_not_register"));
            return;
          }

          if (nextPage === "/cadastrar") {
            sendCellPhoneToApi(cellPhone);
          }
        }
        //USUÁRIO TEM CADASTRO MAS NÃO TEM O PARTNER_LIST IVOCLAR
        else if (resultUser && !hasPartnerIdIvoclar) {
          // VERIFICA SE O USUÁRIO PARTICIPA DA CAMPANHA ATUAL.
          const resultCampaign = await getCampaign(
            resultUser.uId,
            CAMPAIGN_ID,
            "1"
          );
          setIsPartipate(resultCampaign.data.status);
          setIsRegistered(true);

          if (nextPage === "triibo-web") {
            setHasPartnerList(false);
            setLoading(false);
            setMessageErroCellPhone(
              t("messageError.common.phone_not_participating_campaing")
            );
            return;
          }

          sendCellPhoneToApi();
        }

        //USUÁRIO CADASTRADO E TEM O PARTNER_LIST IVOCLAR
        else {
          // VERIFICA SE O USUÁRIO PARTICIPA DA CAMPANHA ATUAL.
          const resultCampaign = await getCampaign(
            resultUser.uId,
            CAMPAIGN_ID,
            "1"
          );

          setIsPartipate(resultCampaign.data.status);
          setIsRegistered(true);
          setHasPartnerList(true);

          if (nextPage === "triibo-web" && !resultCampaign.data.status) {
            setHasPartnerList(false);
            setLoading(false);
            setMessageErroCellPhone(
              t("messageError.common.phone_not_participating_campaing")
            );
            return;
          }

          if (nextPage === "/cadastrar" && resultCampaign.data.status) {
            setLoading(false);
            setIsRegisteredPhone(true);
            setMessageErroCellPhone(
              t("user.alerts.cellphone_already_campaign")
            );
            return;
          }

          if (nextPage === "/cadastrar" && !resultCampaign.data.status) {
            sendCellPhoneToApi(cellPhone);
            return;
          }

          if (nextPage === "triibo-web" && resultCampaign.data.status) {
            sendCellPhoneToApi();
            return;
          }
        }
      })
      .catch((error) => {
        console.log("CONTEXT_USER_ERROR: ", error);
        setLoading(false);
        setMessageErroCellPhone(t("user.alerts.went_wrong"));
      });
  }

  async function sendCellPhoneToApi() {
    setLoading(true);
    if (user !== null) {
      authCode_v1(cellPhone, sendCodeType)
        .then((result) => {
          setLoading(false);
          setIsSendPhone(true);
          setTransactionId(result.transactionId);
        })
        .catch((error) => {
          setLoading(false);
          setIsSendPhone(false);
          setMessageErroCellPhone(
            error?.messageError === "Exceeded the number of requests!"
              ? t("user.alerts.number_attempts_exceeded")
              : t("user.alerts.went_wrong")
          );
        });
    } else {
      authCode_v1(cellPhone, sendCodeType)
        .then((result) => {
          setLoading(false);
          setIsSendPhone(true);
          setTransactionId(result.transactionId);
        })
        .catch((error) => {
          setLoading(false);
          setIsSendPhone(false);
          setMessageErroCellPhone(
            error?.messageError === "Exceeded the number of requests!"
              ? t("user.alerts.number_attempts_exceeded")
              : t("user.alerts.went_wrong")
          );
        });
    }
  }

  async function sendCodeToApi() {
    const isCodeNumberValid = codeSms.length === 6;
    setLoading(true);
    if (isCodeNumberValid) {
      validateSMS_v1(user.uId, codeSms, cellPhone, transactionId)
        .then(async (result) => {
          if (result.data.success) {
            if (nextPage === "triibo-web" && !isParticipate) {
              history.push("/cadastrar");
              setLoading(false);
              return;
            }

            if (nextPage === "triibo-web" && isParticipate) {
              let emailLogin = user?.triiboId?.replace(/[,]/gi, ".");
              //LOGA O USUARIO NO FIREBASE
              await loginUser(emailLogin, user.passPhrase);
              window.location.href = LINK_WEB_APP_LOGADO;
              localStorage.setItem("isLogged", "true");
              // setLoading(false);
              return;
            }
            //SE NÃO TIVER CADASTRO CRIA UM NOVO USUÁRIO
            else if (!isRegistered) {
              createNewUser();
            } else {
              setLoading(false);
              nextPage === "/cadastrar"
                ? history.push(nextPage)
                : addUserData();
            }
          } else {
            setMessageErroCode("Código inválido");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("FUNC VALIDATE ERROR: ", error);
          setLoading(false);
          setMessageErroCode(
            error?.messageError
              ? t("user.alerts.invalid_code")
              : t("user.alerts.went_wrong")
          );
        });
    } else {
      setLoading(false);
      setMessageErroCode(t("user.alerts.invalid_code_min_six"));
    }
  }

  return (
    <UserContext.Provider
      value={{
        authenticated,
        checkUserWithCellPhone,
        sendCodeToApi,
        setCellPhoneUser,
        sendCodeType,
        setSendCodeType,
        cellPhone,
        setCodeSms,
        setOperatorCpf,
        operatorCpf,
        hasPartnerList,
        setLoading,
        loading,
        transactionId,
        isSendPhone,
        messageErroCellPhone,
        setMessageErroCellPhone,
        messageErroCode,
        setMessageErroCode,
        hasRegistration,
        message,
        setMessage,
        isRegisteredUser,
        setIsRegisteredUser,
        user,
        setIsSendPhone,
        setHasRegistration,
        isRegisteredPhone,
        setIsRegisteredPhone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default ProviderUser;
