import axios from "axios";
import { decrypt, encrypt } from "../../utils/helpers";
import { configJson } from "../firebaseUtils";

function getSecurityCode() {
  return new Promise(async (resolve, reject) => {
    let auth = window.btoa(configJson.username + ":" + configJson.password);

    const config = {
      method: "get",
      url: configJson.API_HOST_NODE + "/auth",
      headers: {
        Authorization: "Basic " + auth,
      },
    };

    axios(config)
      .then(function (sc) {
        let decry = JSON.parse(
          decrypt(sc.data.securityCode, configJson.SECURITYCODE_KEY)
        );
        let result = { code: decry.code * 3 };
        result = encrypt(JSON.stringify(result), configJson.SECURITYCODE_KEY);
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function authTokenWithSC(uid) {
  return new Promise(async (resolve, reject) => {
    let sc = await getSecurityCode();
    let auth = window.btoa(configJson.username + ":" + configJson.password);
    const config = {
      method: "post",
      url: configJson.API_HOST_NODE + "/auth",
      headers: {
        Authorization: "Basic " + auth,
        securityCode: sc,
      },
    };

    if (uid) {
      config.headers.uid = uid;
    }

    axios(config)
      .then(function (response) {
        // console.log("response", response.data.token);
        resolve(response.data.token);
      })
      .catch(function (error) {
        // console.log("error", error);
        reject(error);
      });
  });
}
