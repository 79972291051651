import styled from "styled-components";

export const ContainerSendCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span:first-of-type {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    margin-top: 1rem !important;
  }

  span:last-of-type {
    display: flex;
    flex-direction: row;
  }
`;

const colors = {
  ultraBlue: "#005091",
  lightBlue: "#42baef",
};

export const RadioButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  border-radius: 7px;
  border: 0;
  background-color: ${(props) =>
    props.isactive ? colors.ultraBlue : colors.lightBlue};
  color: #fff;
  font-size: 16px;
  margin: 0 1rem 1rem 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;
