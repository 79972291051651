import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(countries, representatives, cities) {
  return { countries, representatives, cities };
}
const rows = [
  createData("Guatemala", "Magno Dental, Denteco", null),
  createData("Colombia", "Leivas Dental, Representaciones Eurodent", null),
  createData("Ecuador", "Depósito Dental Krobalto", null),
  createData("Paraguay", "Dental Guaraní", null),
  createData("Bolivia", "Fernanda Romero", null),
  createData("Chile", "Pareja Lecaros Chile", null),
  createData("Costa Rica", "Cosmodent", null),
  createData("República Dominicana", "De los Santos Dental", null),
  createData("Perú", "Pareja Lecaros Perú", null),
  createData("Argentina", "Carrizo Dental, Olympic Dental", null),
  createData("Uruguay", "Dental Castro", null),
  createData("México", "DEPOSITO DENTAL DEPODENT", "Edo. De Mex."),
  createData(null, "Pleyadent", "Toluca, Mex."),
  createData(null, "KEIKO", "Puebla, Pue."),
  createData(null, "PROMADENT", "Villahermosa Tab."),
  createData(null, "MOVIDENT", "Veracruz, Ver."),
  createData(null, "DEPOSITO DM", "Villahermosa, Tab."),
  createData(null, "VITAL DENTAL, EXPRESS", "Cancún, Q. Roo"),
  createData(null, "DEPOSITO DENTAL PUEBLA", "Tuxtla Gutierrez, Chis."),
  createData(null, "CERAMIC DENTAL SPOT", "Tijuana, B.C."),
  createData(null, "DEPOSITO DENTAL SONORA (Tijuana)", "Hermosillo, Son."),
  createData(null, "ALODENT", "Morelia, Mich."),
  createData(null, "ARAMARA DENTAL", "Guadalajara, Jal."),
  createData(null, "ARTEC DENTAL", "Zapopan, Jal."),
  createData(null, "GAMMA DENTS", "Monterrey, N.L."),
  createData(null, "STANFORD", "San Luis Potosí, S.L.P."),
  createData(null, "GARDENT León", "León, Gto."),
  createData(null, "DISTRIBUIDORA ODONTOLOGICA VICEFI", "Monterrey, N.L."),
  createData(null, "DENTEC", "Mérida, Yuc"),
  createData(null, "DENTAL PROGRESO", "Nuevo Progreso, Tamps."),
  createData(null, "DDL DENTAL LEON", "León, Gto."),
  createData(null, "LEOZ", "Cd. Juarez , Chih."),
  createData(null, "PROMOVAGO DE LA LAGUNA", "Torreón, Coah."),
  createData(null, "DENTAL GUADALUPE", "Guadalupe, Zac."),
];

export function TableDistribuidores() {
  let whatScreen = window.screen.width;

  if (whatScreen >= 450) {
    return (
      <TableContainer
        component={Paper}
        style={{
          marginTop: "5rem",
          overflowY: "scroll",
          height: "500px",
        }}
      >
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Países</b>
              </TableCell>
              <TableCell align="right">
                <b>Distribuidores</b>
              </TableCell>
              <TableCell align="right">
                <b>Ciudades</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.representatives}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.countries}
                </TableCell>
                <TableCell align="right">{row.representatives}</TableCell>
                <TableCell align="right">{row.cities}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer
        component={Paper}
        style={{
          marginTop: "5rem",
          overflowY: "scroll",
          height: "500px",
          padding: "1rem",
          lineHeight: "1.5rem",
        }}
      >
        <b>Países: </b>
        {rows[0].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[0].representatives}

        <hr />

        <b>Países: </b>
        {rows[1].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[1].representatives}

        <hr />

        <b>Países: </b>
        {rows[2].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[2].representatives}

        <hr />

        <b>Países: </b>
        {rows[3].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[3].representatives}

        <hr />

        <b>Países: </b>
        {rows[4].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[4].representatives}

        <hr />

        <b>Países: </b>
        {rows[5].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[5].representatives}

        <hr />

        <b>Países: </b>
        {rows[6].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[6].representatives}

        <hr />

        <b>Países: </b>
        {rows[7].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[7].representatives}

        <hr />

        <b>Países: </b>
        {rows[8].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[8].representatives}

        <hr />

        <b>Países: </b>
        {rows[9].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[9].representatives}

        <hr />

        <b>Países: </b>
        {rows[10].countries}
        <br />
        <b>Distribuidores: </b>
        {rows[10].representatives}

        <hr />

        <b>Países: </b>
        {rows[11].countries}
        <br />
        <b>Ciudades: </b>
        {rows[11].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[11].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[12].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[12].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[13].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[13].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[14].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[14].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[15].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[15].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[16].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[16].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[17].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[17].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[18].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[18].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[19].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[19].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[20].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[20].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[21].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[21].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[22].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[22].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[23].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[23].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[24].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[24].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[25].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[25].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[26].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[26].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[27].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[27].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[28].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[28].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[29].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[29].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[30].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[30].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[31].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[31].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[32].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[32].representatives}

        <br />
        <br />

        <b>Ciudades: </b>
        {rows[33].cities}
        <br />
        <b>Distribuidores: </b>
        {rows[33].representatives}
      </TableContainer>
    );
  }
}
